/**
 * @flow
 *
 * @format
 */
import { ItemTypes } from './BaseItem';
import type { ItemTypesType } from './BaseItem';
import LocalizedString from './LocalizedString';
import LocalizedStringArray from './LocalizedStringArray';
import LocalizedFile from './LocalizedFile';
import AtlObject from './AtlObject';

/* ***************************
  CLASS
*************************** */
export type AppAMS = {};
export type AmsEngineVersion = {
  [engineVersion: number]: {
    amsVersion: string,
  },
};

export default class AMSItem extends AtlObject<AMSItem> {
  images: LocalizedFile[];

  cityId: string;

  coordinate: {
    latitude: number,
    longitude: number,
    altitude: number,
  };

  height: number;

  startYear: number;

  name: LocalizedString;

  subtitle: LocalizedString;

  contentText: LocalizedString;

  annotations: LocalizedString;

  version: ?string;

  versionPerEngine: AmsEngineVersion;

  type: ItemTypesType;

  constructor(json: any) {
    if (json instanceof AMSItem) {
      super(json);
    } else {
      super(json);
    }
    const {
      cityId,
      images,
      coordinate,
      height,
      startYear,
      name,
      subtitle,
      contentText,
      annotations,
      versionPerEngine,
      version,
    } = json;
    this.images = [];
    if (images) {
      this.images = images.map((img) => new LocalizedFile(this.id, undefined, img));
    }
    this.coordinate = coordinate
      ? { ...coordinate }
      : {
          latitude: 0,
          longitude: 0,
          altitude: 0,
        };
    this.type = ItemTypes.AMS;
    this.cityId = cityId;
    this.height = height;
    this.startYear = startYear;
    this.name = new LocalizedString(`${this.id}_name`, name);
    this.subtitle = new LocalizedString(`${this.id}_subtitle`, subtitle);
    this.contentText = new LocalizedString(`${this.id}_contentText`, contentText);
    this.annotations = new LocalizedString(`${this.id}_annotations`, annotations, false);
    this.type = ItemTypes.AMS;
    this.version = version;
    this.versionPerEngine = versionPerEngine ? { ...versionPerEngine } : {};
  }

  serializeInheritedFieldsForApp() {
    const res: any = super.serializeInheritedFieldsForApp();
    res.type = this.type;
    res.cityId = this.cityId;
    res.images = this.images.map((it) => it.serializeForApp());
    res.coordinate = this.coordinate;
    res.height = this.height;
    res.startYear = this.startYear;
    res.name = this.name && this.name.serializeForApp();
    res.subtitle = this.subtitle && this.subtitle.serializeForApp();
    res.contentText = this.contentText && this.contentText.serializeForApp();
    res.annotations = this.annotations && this.annotations.serializeForApp();
    res.versionPerEngine = { ...this.versionPerEngine };
    if (this.version) {
      res.version = this.version;
    }
    if (this.versionPerEngine) {
      res.versionPerEngine = { ...this.versionPerEngine };
    }
    return res;
  }

  serializeInheritedFields() {
    const res: any = super.serializeInheritedFields();
    res.type = this.type;
    res.cityId = this.cityId;
    res.images = this.images.map((it) => it.serialize());
    res.coordinate = this.coordinate;
    res.height = this.height;
    res.startYear = this.startYear;
    res.name = this.name && this.name.serialize();
    res.subtitle = this.subtitle && this.subtitle.serialize();
    res.contentText = this.contentText && this.contentText.serialize();
    res.annotations = this.annotations && this.annotations.serialize();
    if (this.version) {
      res.version = this.version;
    }
    if (this.versionPerEngine) {
      res.versionPerEngine = { ...this.versionPerEngine };
    }
    return res;
  }

  getLocalizedStringsWithPath() {
    const res: { [path: string]: LocalizedString | LocalizedStringArray } = super.getLocalizedStringsWithPath();
    res.name = this.name;
    res.subtitle = this.subtitle;
    res.contentText = this.contentText;
    res.annotations = this.annotations;
    return res;
  }

  getLocalizedFilesWithPath() {
    const res = super.getLocalizedFilesWithPath();
    this.images.forEach((img) => {
      res[`images.${img.index}`] = img;
    });
    return res;
  }

  managedLocales(): string[] {
    return Object.keys(this.name.values);
  }

  // eslint-disable-next-line class-methods-use-this
  getTranslationCsvIdPrefix() {
    return 'ams';
  }
}
