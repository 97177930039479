/**
 * @flow
 *
 * @format
 */
import React from 'react';

import { connect } from 'react-redux';

import { TabContent } from '../components';
import UserRightsEditionWidget from './UserRightsEditionWidget';
import UserCreateWidget from './UserCreateWidget';

type Props = {};

type State = {};

class UsersTab extends React.PureComponent<Props, State> {
  static defaultProps = {};

  state = {};

  // eslint-disable-next-line class-methods-use-this
  render() {
    return (
      <TabContent name="users" active>
        <UserCreateWidget />
        <UserRightsEditionWidget />
      </TabContent>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: state.preferences.editionLocale,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UsersTab);
