/**
 * @flow
 *
 * @format
 */
import * as React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';

export interface InputBooleanProps {
  fieldName: string;
  value: boolean;
  label: string;
  help?: ?string;
  disabled?: boolean;
  handleChange: (event: { target: { value: boolean, id: string } }) => any;
  hidden?: boolean;
  separatorBefore?: boolean;
  onFocus?: () => any;
}

export default class InputBoolean extends React.Component<InputBooleanProps> {
  static defaultProps = {
    disabled: false,
    hidden: false,
    separatorBefore: false,
  };

  handleBooleanChange = (event: any) => {
    const { handleChange } = this.props;
    if (handleChange) {
      handleChange({
        target: { value: event.target.checked, id: event.target.id },
      });
    }
  };

  render() {
    const { fieldName, value, label, help, disabled, hidden, separatorBefore, onFocus } = this.props;

    return (
      <div className="mb-0" key={fieldName} hidden={hidden}>
        {separatorBefore && <hr />}
        <div className="row checkbox-row m-1">
          <div className="col-xs-2 col-xs-offset-4">
            <div className="checkbox-inline">
              <label htmlFor={fieldName} className="checkbox-inline">
                <input
                  type="checkbox"
                  id={fieldName}
                  className="mr-2"
                  onChange={this.handleBooleanChange}
                  checked={value}
                  disabled={disabled}
                  aria-describedby={`${fieldName}Help`}
                  placeholder={''}
                  onFocus={onFocus}
                />
                {label}
              </label>
              {help && help.length && (
                <small id={`${fieldName}Help`} className="form-text text-muted">
                  {help}
                </small>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
