/**
 * @flow
 *
 * @format
 */
import * as React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import logo from 'src/assets/logo ATLANTIDE.png';
import { withTranslation } from 'react-i18next';
import { unregister } from 'src/registerServiceWorker';
// import '../../../assets/styles/app.scss';

export interface LoaderProps {
  startTime?: number;
  maintainers?: string[];
  requiredVersion?: string;
  t: (key: string) => string;
}

class MaintenanceLoader extends React.Component<LoaderProps> {
  // eslint-disable-next-line class-methods-use-this
  componentDidMount() {
    unregister();
    if (!this.props.maintainers?.length) {
      window.location.reload(false);
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div className="maintenanceLoaderContainer loaderContainer">
        <img className="loaderLogo" src={logo} alt="logo" x="145" y="142" height="56px" width="50px" />
        <svg className="loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
          <circle cx="170" cy="170" r="160" stroke="#001B48" />
          <circle cx="170" cy="170" r="135" stroke="#00E0CC" />
          <circle cx="170" cy="170" r="110" stroke="#FC1472" />
          <circle cx="170" cy="170" r="85" stroke="#E8EDF3" />
        </svg>
        {this.props.maintainers ? (
          <p className="maintenanceData">
            {t('general.maintenancePlaceholder')}
            <br />
            {`${t('general.maintainers')}: ${this.props.maintainers.join(' - ')}`}
            <br />
            {`${t('general.since')}: ${new Date(this.props.startTime).toString()}`}
            <br />
          </p>
        ) : (
          <p className="maintenanceData">
            {t('general.updateTitle')}
            <br />
            {t('general.updateMessage')}
            <br />
            {t('general.updateTip')}
            <br />
            <br />
            {`${t('general.requiredVersionPlaceholder')}: ${this.props.requiredVersion}`}
            <br />
          </p>
        )}
      </div>
    );
  }
}
export default withTranslation('default')(MaintenanceLoader);
