/**
 * @flow
 *
 * @format
 */
import type { ReduxDispatch } from 'redux';
import * as Globals from 'src/constants/globals';
import { AMSItem } from 'src/data';
import type { AmsEngineVersion } from 'src/data/AMSItem';
import Firebase, { FirebaseHelper, CatalogTypes } from 'src/services/Firebase';
import { EventsServiceHelper, NotificationTypes } from 'src/store/events';
import { asyncForEach } from 'src/utils';
import type { AMSReducerState } from './AmsReducer';
import type { TranslationItemsType } from '../scenario/ScenarioServiceHelper';
import * as actions from './actions';

const logHelperCall = (title, args) => {
  if (Globals.__DEV__) {
    console.log(`################# AmsServiceHelper / ${title}`, args);
  }
};

const saveAmsInFirebase = async (amsId: string, ams: AMSItem, firebase: Firebase) => {
  const serialized = ams.serializeForFirebase();
  await firebase.ams(amsId).set(serialized);
};

export type updateAmsType = (
  id: string,
  amsData: any,
  firebase: Firebase,
  shouldNotify?: boolean,
) => (ReduxDispatch) => Promise<void>;
export const updateAms: updateAmsType = (id, amsData, firebase, shouldNotify) => async (dispatch) => {
  logHelperCall('updateAms', { id, amsData });
  const ams = new AMSItem(amsData);
  const shouldUploadFile = ams.hasFileToUpload();
  if (shouldUploadFile) {
    const localizedFiles = ams.getLocalizedFiles();
    const version = 'v1'; // TODO : May we manage versions ?
    if (localizedFiles && firebase) {
      await asyncForEach(localizedFiles, async (img) => {
        await asyncForEach(Object.keys(img.files), async (locale) => {
          const file = img.files[locale];
          if (file.contentToUpload) {
            const ext = file.contentToUpload.name.split('.').pop();
            const url = await FirebaseHelper.pushAmsEditorAssetAsync(
              id,
              img.getStorageFileName(locale, version, ext),
              file.contentToUpload,
              firebase,
            );
            // eslint-disable-next-line no-param-reassign
            img.ext = ext;
            file.name = img.getStorageFileName(locale, version, ext);
            file.version = version;
            file.url = url;
            file.ext = ext;
            delete file.contentToUpload;
          }
        });
      });
    }
  }
  await saveAmsInFirebase(ams.id, ams, firebase);
  await saveAmsInFirebase(ams.id, ams, firebase);
  dispatch(actions.updateAms(id, ams));
  if (shouldNotify) {
    EventsServiceHelper.addNotif(NotificationTypes.SUCCESS, 'S_AMS_SAVED_IN_DB')(dispatch);
  }
};

export type createAmsType = (
  amsData: any,
  checkExistance: boolean,
  firebase: Firebase,
) => (ReduxDispatch) => Promise<void>;
export const createAms: createAmsType = (amsData, checkExistance = true, firebase) => async (dispatch) => {
  logHelperCall('createAms', { amsData });
  const existReq = await firebase.ams(amsData.id).once('value');
  if (checkExistance && existReq.exists()) {
    throw new Error('AMS already exists');
  } else {
    updateAms(amsData.id, amsData, firebase, false)(dispatch);
  }
};

export type applyTranslationsType = (
  items: AMSReducerState,
  translations: TranslationItemsType,
  firebase: Firebase,
) => (dispatch: ReduxDispatch) => Promise<void>;
export const applyTranslations: applyTranslationsType = (items, translations, firebase) => async (dispatch) => {
  logHelperCall('applyTranslations', { items, translations });
  await asyncForEach(Object.keys(items), async (amsId) => {
    const amsTranslation = translations.ams[amsId];
    if (amsTranslation) {
      items[amsId].applyTranslations(amsTranslation);
      await updateAms(amsId, items[amsId], firebase)(dispatch);
    }
  });
};

export const formatTranslation = (translationJson: any[]) => {
  const translationObj = { ams: {} };
  if (translationJson) {
    translationJson.forEach((line) => {
      const { path, ...trads } = line;
      const pathSteps = path.split('.');
      const scenarioPart = pathSteps.shift();
      let current = translationObj[scenarioPart];
      const id = pathSteps.shift();
      if (!current[id]) {
        current[id] = {};
      }
      current = current[id];
      const internalPath = pathSteps.join('.');
      current[internalPath] = trads;
    });
  }
  return translationObj;
};

export type removeAmsType = (id: string, firebase: Firebase) => (ReduxDispatch) => Promise<void>;
export const removeAms: removeAmsType = (id, firebase) => async (dispatch) => {
  logHelperCall('removeAms', id);
  await firebase.ams(id).remove();
  dispatch(actions.removeAms(id));
};

// IMPORT
// *********************
export type importAmssType = (data: any, firebase: Firebase) => (ReduxDispatch) => void;
export const importAmss: importAmssType = (data, firebase) => (dispatch) => {
  logHelperCall('importAms', { data });
  if (data) {
    Object.keys(data).forEach((amsId) => {
      const ams = { id: amsId, ...data[amsId] };
      createAms(ams, false, firebase)(dispatch);
    });
  }
};

export type loadAMSFromFirebaseType = (firebase: Firebase) => (ReduxDispatch) => Promise<void>;
export const loadAMSFromFirebase: loadAMSFromFirebaseType = (firebase) => async (dispatch) => {
  logHelperCall('loadAMSFromFirebase');
  const values = await firebase.amss().once('value');
  const amss = values.val();
  await importAmss(amss, firebase)(dispatch);
};

// EXPORT
// *********************

export type exportAmsTranslationType = (
  state: AMSReducerState,
  locales: string[],
) => (ReduxDispatch) => { headers: { [key: string]: string }[], lines: string[][] };
export const exportAmsTranlations: exportAmsTranslationType = (state: AMSReducerState, locales: string[]) => () => {
  let lines = [];
  Object.values(state).forEach((item) => {
    if (item instanceof AMSItem && item) {
      lines = lines.concat(item.getLocalizedStringToTranslateWithPath());
    }
  });
  const headers = [{ key: 'path', label: 'technicalPath' }];
  locales.forEach((locale) => {
    headers.push({ key: locale, label: locale });
  });
  return { headers, lines };
};

// RELEASING
// **********************

const cleanupUnusedData = (
  amsId: string,
  engineVersions: AmsEngineVersion[],
  firebase: Firebase,
  dryRun: boolean,
) => async (dispatch) => {
  let versionsTokeep = [];
  engineVersions.forEach((it) => {
    versionsTokeep = versionsTokeep.concat(Object.values(it));
  });
  const versionsTokeepNbrs = versionsTokeep.map((it) => Number.parseInt(it.amsVersion.substr(1), 10));
  const max = Math.max(...versionsTokeepNbrs);
  const deleted = { files: [], indexVersions: [] };
  for (let i = 1; i < max; i += 1) {
    if (!versionsTokeepNbrs.includes(i)) {
      try {
        deleted.indexVersions.push(`v${i}`);
        if (!dryRun) {
          // eslint-disable-next-line no-await-in-loop
          await firebase.amsDataVersion(amsId, `v${i}`).remove();
        }
      } catch (error) {
        EventsServiceHelper.addNotif(NotificationTypes.WARN, 'W_AMS_VERSIONS_CLEANING_FAILED', error.message)(dispatch);
      }
    }
  }
  return deleted;
};

export const generateSingleRelease = (
  amsToUpload: AMSItem,
  engineVersion: number,
  firebase: Firebase,
  dryRun: boolean,
) => async (dispatch: ReduxDispatch) => {
  console.log('Starting AMS release process for ', amsToUpload.id);
  console.log('Releasing for engine version ', engineVersion);
  const successNotifType = dryRun ? NotificationTypes.DEBUG : NotificationTypes.SUCCESS;
  const newAms = new AMSItem(amsToUpload);
  // Get the new version number
  const version = await FirebaseHelper.getAMSNextVersionAsync(newAms.id, firebase);
  newAms.version = version;
  const oldEngineVersion = newAms.versionPerEngine[engineVersion];
  newAms.versionPerEngine[engineVersion] = {
    amsVersion: version,
  };

  // TODO : Limit number of engine version managed here !
  console.debug('Previous engine was: ', oldEngineVersion);

  // List AMS assets per locale
  const locales = [...newAms.managedLocales(), 'default'];
  const amsFiles = newAms.getFilesPerLocale(locales);

  console.log('Start pushing assets to release storage.');
  let success = true;
  const filesTransferRes = {
    downloaded: [],
    pushed: [],
    failed: [],
    unchanged: [],
  };
  await asyncForEach(locales, async (locale) => {
    console.log(`--- Start pushing ${locale} assets.`);
    await asyncForEach(amsFiles[locale], async (fileInfo: any) => {
      // Copy all new files to the release storage
      if (success && fileInfo.version === version) {
        console.log(`------ Coping ${fileInfo.storageName} to release for locale ${locale}`);
        try {
          const file: ?File = await FirebaseHelper.downloaAmsAsset(newAms.id, fileInfo.storageName, firebase);
          filesTransferRes.downloaded.push(fileInfo.storageName);
          if (file && !dryRun) {
            await FirebaseHelper.pushAmsReleaseAsset(
              newAms.id,
              version,
              'assets',
              fileInfo.storageName,
              file,
              undefined,
              fileInfo.public,
              firebase,
            );
            filesTransferRes.pushed.push(fileInfo.storageName);
          }
        } catch (error) {
          console.error(error);
          filesTransferRes.failed.push(fileInfo.storageName);
          success = false;
          EventsServiceHelper.addNotif(
            NotificationTypes.ERROR,
            'E_AMS_RELEASE_FILE_COPY_ISSUE',
            `${fileInfo.storageName}: ${error.message}`,
          )(dispatch);
          EventsServiceHelper.addNotif(
            NotificationTypes.ERROR,
            'E_AMS_RELEASE_FAILED',
            `${newAms.id}: ${version}`,
          )(dispatch);
          throw new Error('E_AMS_RELEASE_FAILED');
        }
      } else {
        filesTransferRes.unchanged.push(fileInfo.storageName);
      }
    });
    console.log(`--- Pushed ${locale} assets.`);
  });
  console.log('File transfer res:', filesTransferRes);

  const serializedAms = newAms.serializeForFirebase(true);

  if (!dryRun) {
    try {
      await firebase
        .amsIndex(newAms.id, CatalogTypes.dev)
        .set({ coordinate: newAms.coordinate, version: newAms.version, versionPerEngine: newAms.versionPerEngine });
      await firebase.amsDataVersion(newAms.id, version).set(serializedAms);
    } catch (error) {
      EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_AMS_RELEASE_DATA_FAILED', error.message)(dispatch);
      EventsServiceHelper.addNotif(
        NotificationTypes.ERROR,
        'E_AMS_RELEASE_FAILED',
        `${newAms.id}: ${version}`,
      )(dispatch);
      throw new Error('E_AMS_RELEASE_FAILED');
    }
  }
  try {
    const updatedScenarios = await FirebaseHelper.updateScenarioForAmsAsync(
      newAms,
      version,
      CatalogTypes.dev,
      firebase,
      dryRun,
    );
    console.log(`Folowing scenarios were using the AMS ${newAms.id}`, updatedScenarios);
  } catch (error) {
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_AMS_SCENARIO_LINK_FAILED', error.message)(dispatch);
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_AMS_RELEASE_FAILED', `${newAms.id}: ${version}`)(dispatch);
    throw new Error('E_AMS_RELEASE_FAILED');
  }
  if (!dryRun) {
    await updateAms(newAms.id, newAms, firebase, false)(dispatch);
  }
  const versions = [newAms.versionPerEngine];
  try {
    const prodSnapshot = await firebase.amsIndex(newAms.id, CatalogTypes.prod).once('value');
    const currentProdVersion = prodSnapshot.exists() && prodSnapshot.val();
    if (currentProdVersion) {
      versions.push(currentProdVersion.versionPerEngine);
    }
    const cleanRes = await cleanupUnusedData(newAms.id, versions, firebase, dryRun)(dispatch);
    console.log('Cleanup result :', cleanRes);
  } catch (error) {
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'W_AMS_VERSIONS_CLEANING_FAILED', error.message)(dispatch);
  }
  EventsServiceHelper.addNotif(successNotifType, 'S_AMS_RELEASED_DEV', newAms.id)(dispatch);
};

export type bulkGenerateReleaseType = (
  amsToUpload: AMSItem[],
  engineVersion: number,
  firebase: Firebase,
  dryRun: boolean,
) => (ReduxDispatch) => Promise<void>;
export const bulkGenerateRelease: bulkGenerateReleaseType = (
  amsToUpload,
  engineVersion,
  firebase,
  dryRun = false,
) => async (dispatch) => {
  const failedAms = [];
  const succeededAmss = [];
  const successNotifType = dryRun ? NotificationTypes.DEBUG : NotificationTypes.SUCCESS;
  await asyncForEach(amsToUpload, async (ams) => {
    try {
      await generateSingleRelease(ams, engineVersion, firebase, dryRun)(dispatch);
      succeededAmss.push(ams.id);
    } catch (_error) {
      console.log(_error);
      failedAms.push(ams.id);
    }
  });
  if (!failedAms.length) {
    EventsServiceHelper.addNotif(successNotifType, 'S_AMS_BULK_RELEASE_DEV', succeededAmss.join(', '), 0)(dispatch);
  } else {
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'S_AMS_BULK_RELEASE_FAILED', failedAms.join(', '))(dispatch);
  }
};

export const deployAmsToInternal = (amsId: string, firebase: Firebase, dryRun: boolean) => async (
  dispatch: ReduxDispatch,
) => {
  const successNotifType = dryRun ? NotificationTypes.DEBUG : NotificationTypes.SUCCESS;
  const snapshot = await firebase.amsIndex(amsId, CatalogTypes.dev).once('value');
  const currentDevVersion = snapshot.exists() && snapshot.val();
  const prodSnapshot = await firebase.amsIndex(amsId, CatalogTypes.prod).once('value');
  const currentProdVersion = prodSnapshot.exists() && prodSnapshot.val();
  const version = currentDevVersion.version;
  if (currentDevVersion && version) {
    if (currentProdVersion && currentProdVersion.lastVersion === version) {
      EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_AMS_RELEASE_ALREADY_DEPLOYED', version)(dispatch);
      throw new Error('E_AMS_RELEASE_ALREADY_DEPLOYED');
    }
    // Dupplicate index from dev to prod
    if (!dryRun) {
      try {
        await firebase.amsIndex(amsId, CatalogTypes.prod).set(currentDevVersion);
      } catch (error) {
        EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_AMS_DEPLOY_DATA_FAILED', error.message)(dispatch);
        EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_AMS_DEPLOY_PPR_PROD_FAILED', `${amsId}`)(dispatch);
        throw new Error('E_AMS_RELEASE_FAILED');
      }
    }

    let scenarioList = [];
    // Update Production AMS for scenario
    try {
      scenarioList = await FirebaseHelper.updateScenarioForAmsAsync(
        { id: amsId, ...currentDevVersion },
        version,
        CatalogTypes.prod,
        firebase,
        dryRun,
      );
      console.log(`Folowing scenarios were using the AMS ${amsId}`, scenarioList);
    } catch (error) {
      EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_AMS_SCENARIO_LINK_FAILED', error.message)(dispatch);
      EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_AMS_RELEASE_FAILED', `${amsId}: ${version}`)(dispatch);
      throw new Error('E_AMS_RELEASE_FAILED');
    }

    // Cleanup unused data
    const deleteRes = await cleanupUnusedData(amsId, [currentDevVersion.versionPerEngine], firebase, dryRun)(dispatch);
    console.log('Cleaning result: ', deleteRes);
    EventsServiceHelper.addNotif(
      successNotifType,
      'S_AMS_RELEASED_PROD',
      `${amsId} version ${version}. Ams used in : ${scenarioList.join(', ')}`,
    )(dispatch);
  } else {
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_NOTHING_TO_DEPLOY', amsId)(dispatch);
  }
};

export type bulkDeployToInternalAsyncType = (
  amsIds: string[],
  firebase: Firebase,
  dryRun: boolean,
) => (ReduxDispatch) => Promise<void>;
export const bulkDeployToInternalAsync: bulkDeployToInternalAsyncType = (amsIds, firebase, dryRun) => async (
  dispatch,
) => {
  const failedAms = [];
  const succeededAmss = [];
  const successNotifType = dryRun ? NotificationTypes.DEBUG : NotificationTypes.SUCCESS;
  await asyncForEach(amsIds, async (amsId) => {
    try {
      await deployAmsToInternal(amsId, firebase, dryRun)(dispatch);
      succeededAmss.push(amsId);
    } catch (_error) {
      console.log(_error);
      failedAms.push(amsId);
    }
  });
  if (!failedAms.length) {
    EventsServiceHelper.addNotif(successNotifType, 'S_AMS_BULK_RELEASE_DEV', succeededAmss.join(', '), 0)(dispatch);
  } else {
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'S_AMS_BULK_RELEASE_FAILED', failedAms.join(', '))(dispatch);
  }
};

// CLEANUP
// *********************
export type cleanupType = () => (ReduxDispatch) => void;
export const cleanup: cleanupType = () => (dispatch) => {
  logHelperCall('cleanup');
  dispatch(actions.cleanup());
};
