/**
 * @flow
 *
 * @format
 */
import * as types from './types';

export const createAms = (values: any) => ({
  type: types.AMS_CREATE,
  payload: { ams: values },
});
export const updateAms = (amsId: string, values: any) => ({
  type: types.AMS_UPDATE,
  payload: { id: amsId, ams: values },
});
export const removeAms = (amsId: string) => ({
  type: types.AMS_REMOVE,
  payload: { id: amsId },
});

export const cleanup = () => ({
  type: types.AMS_CLEANUP,
  payload: {},
});
