/**
 * @flow
 *
 * @format
 */
import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { FirebaseSingleton } from 'src/services/Firebase';
import { asyncForEach } from 'src/utils';
import { Discussion, ItemTypes, NPC, Scenario } from 'src/data';
import { getNewItem } from 'src/store/scenario/items/ItemsReducer';
import AssetsReview from './AssetsReview';
import WordingReview from './WordingReview';
import MapReview from './MapReview';

type Props = {
  onClick: (info: any) => any,
  info: any,
  locale: string,
  isActive: boolean,
  addError: (errors: any[], item: string) => void,
  onReviewStateChanged: (isReviewing: boolean) => any,
  t: (key: string) => string,
};

const ReviewScenario = (props: Props) => {
  const [history, setHistory] = useState([]);
  const [hasPrevSuccess, setPrevSuccess] = useState(false);
  const [isReviewing, setReviewing] = useState(false);
  const [scenarioItems, setScenarioItems] = useState({});
  const [scenarioHeader, setScenarioHeader] = useState(undefined);
  const [scenarioNpcs, setScenarioNpcs] = useState([]);
  const { t } = props;

  const getSubmissionHistory = async () => {
    if (props.info.scenarioId) {
      try {
        const { scenarioId, version } = props.info;
        const snap = await FirebaseSingleton.closedSubmissions()
          .child(scenarioId)
          .once('value');
        const newHistMap = snap.val() || {};
        const newHist = Object.values(newHistMap)
          .sort((a, b) => b.submissionDate - a.submissionDate)
          .slice(0, 5);
        setHistory(newHist);

        setPrevSuccess(Object.entries(newHistMap).find((it) => it.status === 'validated'));

        const itemsSnap = await FirebaseSingleton.scenarioEditorItemsData(scenarioId, version).once('value');
        const headerSnap = await FirebaseSingleton.scenarioEditorHeader(scenarioId, version).once('value');
        const npcsSnap = await FirebaseSingleton.scenarioEditorNPCs(scenarioId, version).once('value');

        const header = new Scenario(headerSnap.val());
        const items = {};

        const npcs = [];
        await asyncForEach(Object.entries(npcsSnap.val() || {}), async ([npcId, npcData]) => {
          npcs.push(new NPC({ id: npcId, ...npcData }));
        });
        const rawItems = itemsSnap.val();
        if (rawItems) {
          // const itemCount = Object.keys(rawItems).length;
          const { __detachedNodes, ...releasedItems } = rawItems;
          await asyncForEach(Object.entries(releasedItems), async ([itemId, itemData]) => {
            const item = { id: itemId, ...itemData };
            switch (item.type) {
              case ItemTypes.Discussion:
                items[itemId] = new Discussion(item);
                break;
              default:
                items[itemId] = getNewItem(item);
                break;
            }
          });
        }
        setScenarioHeader(header);
        setScenarioNpcs(npcs);
        setScenarioItems(items);
      } catch (error) {
        console.error('Error while getting history', error);
        setHistory([]);
      }
    } else {
      setHistory([]);
    }
  };

  useEffect(() => {
    getSubmissionHistory();
  }, []);

  useEffect(() => {
    getSubmissionHistory();
  }, [props.info.scenarioId]);

  const startReview = async () => {
    // TODO : Notify server to disable submission deletion
    setReviewing(true);
    props.onReviewStateChanged(true);
    await FirebaseSingleton.submission(props.info.scenarioId, props.info.submissionId, true)
      .child('status')
      .set('reviewing');
  };

  const addError = (configuredErrors: any[], currentItem: string) => {
    props.addError(configuredErrors, currentItem);
  };

  const { scenarioId, version, submissionDate, releaseDate, message } = props.info;

  const renderPreview = () => (
    <div className="card">
      <div className="card-header">
        <h5 className="card-title">{t('screens.review.pendingSubmission')}</h5>
      </div>
      <div className="card-body">
        <p className="card-text">
          <strong>{t('screens.review.submissionDate')}</strong>
          {moment(submissionDate).format('DD/MM/YYYY HH:mm')}
          <br />
          <strong>{t('screens.review.releaseDate')}</strong>
          {moment(releaseDate).format('DD/MM/YYYY HH:mm')}
          <br />
          <strong>{t('screens.review.versionNumber')}</strong>
          {version || '??'}
          <br />
          <strong>{t('screens.review.message')}</strong>
          <br />
          {message || 'N/A'}
          <br />
        </p>
      </div>

      <div className="card-footer">
        <button
          className="btn btn-outline-warning ml-2"
          type="button"
          id="button-deploy"
          onClick={startReview}
          disabled={isReviewing}
        >
          {t('screens.review.confirmReview')}
        </button>
      </div>
    </div>
  );

  const renderHistory = () => (
    <div className="card mt-2">
      <div className="card-header">
        <h5 className="card-title">{t('screens.review.history')}</h5>
      </div>
      <div className="card-body">
        <div className="card-text">
          {!history.length && t('screens.review.noHistory')}
          {!!history.length && !hasPrevSuccess && t('screens.review.noPrevSuccess')}
          {history.map((sub, index) => (
            <div key={`${sub.scenarioId}_${sub.submissionId}_${index}`}>
              <strong>{t('screens.review.submissionDate')}</strong>
              {moment(sub.submissionDate).format('DD/MM/YYYY HH:mm')}
              <br />
              <strong>{t('screens.review.versionNumber')}</strong>
              {sub.version || '??'}
              <br />
              <strong>{t('screens.review.status')}</strong>
              {t(`screens.review.statusList.${sub.status}`)}
              <br />
              {!!sub.feed?.length && (
                <React.Fragment>
                  <strong>{t('screens.myReleases.feed')}</strong>
                  {sub.feed.map((notif, index) => (
                    <div className="alert alert-danger mb-2" role="alert" key={`${sub.submissionId}_feed_${index}`}>
                      <div>
                        <strong>{notif.message}</strong>
                        {notif.pathToItem && (
                          <React.Fragment>
                            <br />
                            <u>{t('screens.myReleases.feedPathToItem')}</u>
                            {notif.pathToItem}
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                  ))}
                </React.Fragment>
              )}
              <hr />
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <React.Fragment key={scenarioId}>
      {renderPreview()}
      {renderHistory()}
      {isReviewing && scenarioItems && (
        <React.Fragment>
          <MapReview items={scenarioItems} addError={addError} />
          <AssetsReview items={scenarioItems} npcs={scenarioNpcs} header={scenarioHeader} addError={addError} />
          <WordingReview items={scenarioItems} npcs={scenarioNpcs} header={scenarioHeader} addError={addError} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  locale: state.preferences.editionLocale,
});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation('default'))(ReviewScenario);
