/**
 * @flow
 *
 * @format
 */

export const AMS_CREATE = 'ams/AMS_CREATE';
export const AMS_UPDATE = 'ams/AMS_UPDATE';
export const AMS_REMOVE = 'ams/AMS_REMOVE';
export const AMS_CLEANUP = 'ams/AMS_CLEANUP';
