/**
 * @flow
 *
 * @format
 */
import type { ReduxDispatch } from 'redux';
import { City } from 'src/data';
import Firebase, { FirebaseHelper, CatalogTypes } from 'src/services/Firebase';
import { asyncForEach } from 'src/utils';
import * as Globals from 'src/constants/globals';
import { EventsServiceHelper, NotificationTypes } from 'src/store/events';
import * as actions from './actions';
import * as Routes from '../../constants/routes';

const logHelperCall = (title, args) => {
  if (Globals.__DEV__) {
    console.log(`################# ConfigurationServiceHelper / ${title}`, args);
  }
};

const saveCityInFirebase = async (cityId: string, city: City, firebase: Firebase) => {
  const serialized = city.serializeForFirebase();
  await firebase.city(cityId).set(serialized);
};

export type removeCityType = (cityId: string, firebase: Firebase) => (ReduxDispatch) => Promise<void>;
export const removeCity: removeCityType = (cityId, firebase) => async (dispatch) => {
  logHelperCall('removeCity', cityId);
  await firebase.city(cityId).remove();
  dispatch(actions.removeCity(cityId));
};

export type updateCityType = (
  cityId: string,
  value: City,
  firebase: Firebase,
  shouldNotify?: boolean,
  shouldSaveInFirebase?: boolean,
) => (ReduxDispatch) => Promise<void>;
export const updateCity: updateCityType = (
  cityId,
  value,
  firebase,
  shouldNotify,
  shouldSaveInFirebase = true,
) => async (dispatch) => {
  logHelperCall('updateCity', { cityId, value });
  const city = new City(value);
  const shouldUploadFile = city.hasFileToUpload();
  if (shouldUploadFile) {
    const localizedFiles = city.getLocalizedFiles();
    const version = 'v1'; // TODO : May we manage versions ?
    if (localizedFiles && firebase) {
      await asyncForEach(localizedFiles, async (img) => {
        await asyncForEach(Object.keys(img.files), async (locale) => {
          const file = img.files[locale];
          if (file.contentToUpload) {
            const ext = file.contentToUpload.name.split('.').pop();
            const url = await FirebaseHelper.pushCityEditorAssetAsync(
              cityId,
              img.getStorageFileName(locale, version, ext),
              file.contentToUpload,
              firebase,
            );
            // eslint-disable-next-line no-param-reassign
            img.ext = ext;
            file.name = img.getStorageFileName(locale, version, ext);
            file.version = version;
            file.url = url;
            file.ext = ext;
            delete file.contentToUpload;
          }
        });
      });
    }
  }
  if (shouldSaveInFirebase) {
    await saveCityInFirebase(city.id, city, firebase);
  }
  dispatch(actions.updateCity(cityId, city));
  if (shouldNotify) {
    EventsServiceHelper.addNotif(NotificationTypes.SUCCESS, 'S_CITY_SAVED_IN_DB')(dispatch);
  }
};

export type createCityType = (
  value: City,
  checkExistance: boolean,
  firebase: Firebase,
  shouldSaveInFirebase?: boolean,
) => (ReduxDispatch) => Promise<void>;
export const createCity: createCityType = (
  value,
  checkExistance = true,
  firebase,
  shouldSaveInFirebase = true,
) => async (dispatch) => {
  logHelperCall('createCity', value);
  const existReq = await firebase.city(value.id).once('value');
  if (checkExistance && existReq.exists()) {
    throw new Error('City already exists');
  } else {
    updateCity(value.id, value, firebase, false, shouldSaveInFirebase)(dispatch);
  }
};

export type cleanupType = () => (ReduxDispatch) => void;
export const cleanup: cleanupType = () => (dispatch) => {
  logHelperCall('cleanup');
  dispatch(actions.cleanup());
};

// RELEASING
// **********************
export type generateCityReleaseType = (
  cityToUpload: City,
  engineVersion: string,
  firebase: Firebase,
) => (ReduxDispatch) => Promise<void>;
export const generateCityRelease: generateCityReleaseType = (cityToUpload, engineVersion, firebase) => async (
  dispatch,
) => {
  console.log('Starting city release process for ', cityToUpload.id);
  console.log('Releasing for engine version ', engineVersion);

  const version = 'v1';

  const newCity = new City(cityToUpload);
  // List scenario assets per locale
  const locales = [...newCity.managedLocales(), 'default'];
  const cityFiles = newCity.getFilesPerLocale(locales);

  console.log('Start pushing assets to release storage.');
  let success = true;
  await asyncForEach(locales, async (locale) => {
    console.log(`--- Start pushing ${locale} assets.`);
    await asyncForEach(cityFiles[locale], async (fileInfo: any) => {
      // Copy all new files to the release storage
      if (success && fileInfo.version === version) {
        console.log(`------ Coping ${fileInfo.storageName} to release for locale ${locale}`);
        try {
          const file: ?File = await FirebaseHelper.downloadCityAsset(cityToUpload.id, fileInfo.storageName, firebase);
          if (file) {
            await FirebaseHelper.pushCityReleaseAsset(
              cityToUpload.id,
              version,
              'assets',
              fileInfo.storageName,
              file,
              undefined,
              fileInfo.public,
              firebase,
            );
          }
        } catch (error) {
          console.error(error);
          success = false;
          EventsServiceHelper.addNotif(
            NotificationTypes.ERROR,
            'E_CITY_RELEASED_FAILED_COPY_FILE',
            `${fileInfo.storageName}: ${error.message}`,
          )(dispatch);
          EventsServiceHelper.addNotif(
            NotificationTypes.ERROR,
            'E_CITY_RELEASED_FAILED',
            `${newCity.id}: ${version}`,
          )(dispatch);
          throw new Error('E_CITY_RELEASED_FAILED');
        }
      } else {
        console.log(
          `------ Asset ${fileInfo.name} already up to date for locale ${locale} and version ${fileInfo.version}`,
        );
      }
    });
    console.log(`--- Pushed ${locale} assets.`);
  });

  const serializedCity = newCity.serializeForFirebase(true);
  serializedCity.visibleScenariosCount = 1;
  try {
    await firebase.cityRelease(newCity.id, CatalogTypes.dev).set(serializedCity);
  } catch (error) {
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_CITY_RELEASE_DATA_FAILED', error.message)(dispatch);
    EventsServiceHelper.addNotif(
      NotificationTypes.ERROR,
      'E_CITY_RELEASE_FAILED',
      `${newCity.id}: ${version}`,
    )(dispatch);
    throw new Error('E_CITY_RELEASE_FAILED');
  }
  EventsServiceHelper.addNotif(NotificationTypes.SUCCESS, 'S_CITY_RELEASED_DEV', newCity.id, 0)(dispatch);
};

// IMPORT
// *********************
export type importCityType = (data: any, firebase: Firebase) => (ReduxDispatch) => Promise<void>;
export const importCities: importCityType = (data, firebase) => async (dispatch) => {
  logHelperCall('importCity', { data });
  if (data) {
    await asyncForEach(Object.keys(data), async (cityId) => {
      const city = { id: cityId, ...data[cityId] };
      await createCity(city, false, firebase, false)(dispatch);
    });
  }
};

export type loadCitiesFromFirebaseType = (firebase: Firebase) => (ReduxDispatch) => Promise<void>;
export const loadCitiesFromFirebase: loadCitiesFromFirebaseType = (firebase) => async (dispatch) => {
  logHelperCall('loadCitiesFromFirebase');
  const values = await firebase.cities().once('value');
  const cities = values.val();
  await importCities(cities, firebase)(dispatch);
};

export type loadScreenplayEngineType = (screenPlayJson: any) => (ReduxDispatch) => void;
export const loadScreenplayEngine: loadScreenplayEngineType = (screenPlayJson) => (dispatch) => {
  const res = {};
  Object.keys(screenPlayJson).forEach((key) => {
    const value = screenPlayJson[key];
    const { field1, ...states } = value;
    const authorizedStates = Object.keys(states).filter((state) => states[state] !== 'N/A');
    res[field1] = authorizedStates.map((state) => {
      const stateRegex = /(.*)\((.*)\) *-(.*)/;

      let stateName;
      let metricsId;
      let title;
      const found = state.match(stateRegex);
      if (found && found.length >= 4) {
        stateName = found[1].trim();
        metricsId = found[2].trim();
        title = found[3].trim();
      } else {
        return { success: false, error: new Error(`state is not well formated ${state}: ${JSON.stringify(found)}`) };
      }
      return {
        key: stateName,
        title,
        content: states[state],
        metrics: metricsId,
      };
    });
  });
  console.log('Screenplay after format: ', res);
  dispatch(actions.loadScreenPlay(res));
  return res;
};

export type loadServerConfigType = (firebase: Firebase) => (ReduxDispatch) => Promise<void>;
export const loadServerConfig: loadServerConfigType = (firebase) => async (dispatch) => {
  logHelperCall('loadServerConfig');
  try {
    const snapshot = await firebase.serverConfig().once('value');
    const value = snapshot.val();
    dispatch(actions.loadServerConfig(value));
  } catch (err) {
    console.error(err);
  }
};

export type setTourViewedAsyncType = (
  tourId: string,
  user: User,
  firebase: Firebase,
) => (ReduxDispatch) => Promise<void>;
export const setTourViewedAsync: setTourViewedAsyncType = (tourId, user, firebase) => async () => {
  logHelperCall('getAvailableTours');

  if (!user) {
    return;
  }
  console.log('setWatched');
  await firebase.watchedTour(user.uid, tourId).set(true);
};

export type getAvailableToursType = (path: string, user: User, firebase: Firebase) => (ReduxDispatch) => Promise<void>;
export const getAvailableTours: getAvailableToursType = (path, user, firebase) => async () => {
  logHelperCall('getAvailableTours');

  const routesValue = Object.values(Routes);
  const pathComponents = path.split('/');
  const match = routesValue.find((it) => {
    const urlComponents = it.split('/');
    if (pathComponents.length !== urlComponents.length) {
      return false;
    }
    return urlComponents.every((component, index) => component.startsWith(':') || component === pathComponents[index]);
  });
  if (!match) {
    console.log("No match :'(", path);
    return undefined;
  }
  const snapshot = await firebase
    .editorTours()
    .child(match)
    .child('tours')
    .once('value');
  const tours = snapshot.val();

  const finalTours = [];
  if (tours) {
    console.log(tours);
    await asyncForEach(tours, async (tour) => {
      if (tour) {
        const { id: tourId } = tour;
        const watchedSnap = user && (await firebase.watchedTour(user.uid, tourId).once('value'));
        const watched = user && watchedSnap.val();
        if (tour.conditions) {
          if (
            Object.entries(tour.conditions).every(([condField, condValue]) => {
              switch (condField) {
                case 'watched': {
                  return !!watched === !!condValue;
                }
                case 'user': {
                  return !!user;
                }
                case 'tosAccepted':
                case 'editorTosAgreed':
                case 'editorConfidentialityAgreed':
                case 'studioUserType': {
                  return user && user[condField] === condValue;
                }
                // TODO : Manage eventual other conditions
                default:
                  return true;
              }
            })
          ) {
            finalTours.push(tour);
          }
        } else {
          finalTours.push(tour);
        }
      }
    });
  }
  return finalTours;
  // dispatch(actions.loadAvailableTours(value));
};

export type getARVAccessStatusType = (firebase: Firebase) => (ReduxDispatch) => Promise<boolean>;
export const getARVAccessStatus: getARVAccessStatusType = (firebase) => async () => {
  logHelperCall('getARVAccessStatus');
  try {
    const enableARVAccess = await firebase.getARVAccessStatus();
    return enableARVAccess;
  } catch (err) {
    return false;
  }
};

export type setARVAccessStatusType = (
  firebase: Firebase,
  enableARVAccess: boolean,
) => (ReduxDispatch) => Promise<boolean>;
export const setARVAccessStatus: setARVAccessStatusType = (firebase, enableARVAccess) => async () => {
  logHelperCall('setARVAccessStatus');
  try {
    const status = await firebase.setARVAccessStatus(enableARVAccess);
    return status;
  } catch (err) {
    return false;
  }
};

export type getWelcomeOfferStatusType = (firebase: Firebase) => (ReduxDispatch) => Promise<boolean>;
export const getWelcomeOfferStatus: getWelcomeOfferStatusType = (firebase) => async () => {
  logHelperCall('getWelcomeOfferStatus');
  try {
    const welcomeOfferStatus = await firebase.getWelcomeOfferStatus();
    return welcomeOfferStatus;
  } catch (err) {
    return false;
  }
};

export type setWelcomeOfferType = (
  firebase: Firebase,
  welcomeOfferStatus: boolean,
) => (ReduxDispatch) => Promise<boolean>;
export const setWelcomeOffer: setWelcomeOfferType = (firebase, welcomeOfferStatus) => async () => {
  logHelperCall('setWelcomeOffer');
  try {
    const status = await firebase.setWelcomeOffer(welcomeOfferStatus);
    return status;
  } catch (err) {
    return false;
  }
};

export type notifyEditorErrorType = (
  firebase: Firebase,
  userId: string,
  scenarioId: string,
  error: any,
) => (ReduxDispatch) => Promise<boolean>;
export const notifyEditorError: notifyEditorErrorType = (firebase, userId, scenarioId, error) => async () => {
  logHelperCall('notifyEditorError');
  try {
    const result = await firebase.notifyEditorError(userId, scenarioId, error);
    return result;
  } catch (err) {
    return false;
  }
};
