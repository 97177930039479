/**
 * @flow
 *
 * @format
 */
import * as React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import { uniq } from 'src/utils';
import type { MetricEvent } from 'src/data/BaseItem';
import { TriggeredItemConditions, type TriggeredItemConditionsType } from 'src/data/TriggeredItem';
import type { screenPlayItemType } from 'src/store/configuration/ConfigurationReducer';

import HelpButton from '../HelpButton';
import InputBoolean from './InputBoolean';

export interface InputMetricsProps {
  fieldName: string;
  value: MetricEvent[];
  availableStates: screenPlayItemType;
  itemUnlockedStates: number[];
  label: string;
  disabled?: boolean;
  hidden?: boolean;
  helpInfos: any;
  handleChange: (any) => any;
  separatorBefore?: boolean;
  t: (key: string[]) => string;
  onFocus?: () => any;
}

type state = {
  collapseButtonText: string,
  detailVisible: boolean,
  potentialMetrics: { condition: TriggeredItemConditionsType, title: string, value?: number, metricCode: string }[],
};

export default class InputMetrics extends React.Component<InputMetricsProps, state> {
  static defaultProps = {
    itemId: '',
    disabled: false,
    availableStates: [],
    itemUnlockedStates: [],
    hidden: false,
    helpInfos: undefined,
    separatorBefore: false,
  };

  state = {
    collapseButtonText: '+',
    detailVisible: false,
    potentialMetrics: [],
  };

  componentDidMount = () => {
    this.updatepotentialMetrics(this.props);
  };

  componentDidUpdate = (oldProps: InputMetricsProps) => {
    if (
      this.props.availableStates !== oldProps.availableStates ||
      this.props.itemUnlockedStates !== oldProps.itemUnlockedStates
    ) {
      this.updatepotentialMetrics(this.props);
    }
  };

  updatepotentialMetrics = (props: InputMetricsProps) => {
    const { availableStates, itemUnlockedStates } = props;
    const potentialMetrics = [];
    availableStates.forEach((it) => {
      if (it.key !== TriggeredItemConditions.Unlocked) {
        potentialMetrics.push({ condition: it.key, title: it.title, metricCode: it.metrics });
      } else if (itemUnlockedStates) {
        uniq(itemUnlockedStates).forEach((value) => {
          potentialMetrics.push({
            condition: it.key,
            value,
            title: `${it.title} ${value}`,
            metricCode: `${it.metrics}${value + 1}`,
          });
        });
      }
    });
    this.setState({ potentialMetrics });
  };

  handleChange = (
    condition: TriggeredItemConditionsType,
    conditionValue?: number,
    metricCode: string,
    isSelected: boolean,
  ) => {
    const { value, handleChange, fieldName } = this.props;
    let newVal = [...value];
    newVal = newVal.filter((it) => !(it.condition === condition && it.conditionValue === conditionValue));
    if (isSelected) {
      const metric: MetricEvent = {
        condition,
      };
      if (conditionValue !== undefined) {
        metric.conditionValue = conditionValue;
      }
      newVal.push(metric);
    }
    if (handleChange) {
      handleChange({ target: { id: fieldName, value: newVal } });
    }
  };

  onCollapseClick = () => {
    if (this.state.collapseButtonText === '+') {
      this.setState({
        collapseButtonText: '-',
        detailVisible: true,
      });
    } else {
      this.setState({
        collapseButtonText: '+',
        detailVisible: false,
      });
    }
  };

  hasMetric = (condition: TriggeredItemConditionsType, conditionValue?: number) => {
    const { value } = this.props;
    return value.find((it) => it.condition === condition && it.conditionValue === conditionValue);
  };

  render() {
    const { fieldName, value, label, disabled, hidden, helpInfos, separatorBefore } = this.props;
    const { collapseButtonText, detailVisible, potentialMetrics } = this.state;
    return (
      <div className="form-group hidden" key={fieldName} hidden={hidden}>
        {separatorBefore && <hr />}
        <label className="mb-0" htmlFor={fieldName}>
          {`${label} `}
          {helpInfos &&
            helpInfos.map((helpInfo, index) => (
              <HelpButton
                key={fieldName + index}
                helpStrings={helpInfo.content}
                id={fieldName}
                title={helpInfo.title}
                label={helpInfo.btnLabel}
                display={'inline'}
                className={'ml-auto btn btn-outline-warning ml-2'}
              />
            ))}

          <button type="button" className="btn btn-outline-info btn-sm ml-2" onClick={this.onCollapseClick}>
            {collapseButtonText}
          </button>
        </label>
        {detailVisible &&
          potentialMetrics &&
          potentialMetrics.map((val) => (
            <InputBoolean
              key={val.metricCode}
              fieldName={val.condition}
              disabled={disabled}
              value={!!value && !!this.hasMetric(val.condition, val.value)}
              label={val.title}
              handleChange={(event) => this.handleChange(val.condition, val.value, val.metricCode, event.target.value)}
            />
          ))}
        {!detailVisible && (
          <small id={`${fieldName}Help`} className="form-text text-muted">
            {value &&
              value
                .map((it) => `${it.condition}${it.conditionValue !== undefined ? it.conditionValue : ''}`)
                .join(', ')}
          </small>
        )}
      </div>
    );
  }
}
