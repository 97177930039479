/**
 * @format
 * @flow
 */
import React from 'react';

import { type MissionType } from 'src/data';

type Props = {
  nameSwitch: MissionType,
  missionType: MissionType,
  label: string,
  handleChange: () => void,
};

const SwitchMissionType = ({ nameSwitch, missionType, label, handleChange }: Props) => {
  return (
    <div className="form-check pl-5 pr-5 pt-2 pb-2">
      <input
        className="form-check-input"
        type="radio"
        name={nameSwitch}
        id={nameSwitch}
        value={nameSwitch}
        checked={missionType === nameSwitch}
        onChange={handleChange}
      />
      <label className="form-check-label" htmlFor={nameSwitch}>
        {label}
      </label>
    </div>
  );
};

export default SwitchMissionType;
