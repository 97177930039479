/**
 * @flow
 *
 * @format
 */

import * as _ from 'lodash';

import { NodeModel, DiagramEngine, Toolkit, BaseModelListener, BaseEvent } from 'storm-react-diagrams';
import ItemPortModel from './ItemPortModel';

export const ItemNodeTypes = {
  Message: 'message',
  Answer: 'answer',
  AnswerOpened: 'answerOpened',
  Trigger: 'trigger',
  EntryPoint: 'entryPoint',
  BaseItem: 'baseItem',
};
export type ItemNodeType = $Values<typeof ItemNodeTypes>;

const colors = {
  reachable: {
    entryPoint: '#E8EDF3',
    message: '#001B48',
    answer: '#00E0CC',
    answerOpened: '#00E0CC',
    item: '#FC1472',
    trigger: '#FC1472',
    baseItem: '#001B48',
  },
};

export interface ItemNodeModelListener<T> extends BaseModelListener {
  // eslint-disable-next-line no-use-before-define
  colorChanged?: (event: BaseEvent<ItemNodeModel<T>> & { color: null | string }) => void;
  // eslint-disable-next-line no-use-before-define
  reachableChanged?: (event: BaseEvent<ItemNodeModel<T>> & { reachable: null | boolean }) => void;
}

export class ItemNodeModel<T> extends NodeModel<T> {
  type: ItemNodeType;

  reachable: boolean;

  color: string;

  borderColor: string;

  ports: { [s: string]: ItemPortModel };

  isRoot: boolean;

  constructor(type: ItemNodeType, id: string, reachable: boolean = false, isRoot: boolean = false) {
    super(type, id);
    this.reachable = reachable;
    this.type = type;
    this.color = colors.reachable[type];
    this.borderColor = reachable ? colors.reachable[type] : 'red';
    this.isRoot = isRoot;
    this.initPorts();
  }

  // eslint-disable-next-line class-methods-use-this
  initPorts() {}

  addInPort(label: string): ItemPortModel {
    return this.addPort(new ItemPortModel(true, Toolkit.UID(), label));
  }

  addOutPort(label: string): ItemPortModel {
    return this.addPort(new ItemPortModel(false, Toolkit.UID(), label));
  }

  // eslint-disable-next-line
  inLinkAdded(nodeId: string, modelId: string) {}

  getModelId() {
    return this.id;
  }

  setColor(color: string) {
    this.color = color;
    this.iterateListeners((listener: ItemNodeModelListener<T>, event: BaseEvent) => {
      if (listener.colorChanged) {
        listener.colorChanged({ ...event, color });
      }
    });
  }

  setBorderlColor(color: string) {
    this.borderColor = color;
    this.iterateListeners((listener: ItemNodeModelListener<T>, event: BaseEvent) => {
      if (listener.colorChanged) {
        listener.borderColorChanged({ ...event, color });
      }
    });
  }

  setReachable(reachable: boolean) {
    this.reachable = reachable;
    this.setBorderlColor(reachable ? colors.reachable[this.type] : 'red');
    this.iterateListeners((listener: ItemNodeModelListener<T>, event: BaseEvent) => {
      if (listener.reachableChanged) {
        listener.reachableChanged({ ...event, reachable });
      }
    });
  }

  getId() {
    return this.id;
  }

  // eslint-disable-next-line
  acceptInLinks(outNode: any, port: any, isBeforeAdd: boolean) {
    return true;
  }

  // eslint-disable-next-line
  acceptOutLinks(inNode: any, port: any, isBeforeAdd: boolean) {
    return true;
  }

  deSerialize(object: any, engine: DiagramEngine) {
    super.deSerialize(object, engine);
    this.color = object.color;
  }

  serialize() {
    return _.merge(super.serialize(), {
      type: this.type,
      reachable: this.reachable,
      color: this.color,
    });
  }

  getInPorts(): ItemPortModel[] {
    return _.filter(this.ports, (portModel) => portModel.in);
  }

  getOutPorts(): ItemPortModel[] {
    return _.filter(this.ports, (portModel) => !portModel.in);
  }

  getLinksFromNode = (incomingType: boolean) => {
    const ports = this.ports;
    let res = [];
    Object.values(ports).forEach((port) => {
      if (port && port.in === incomingType && port.links) {
        const links = Object.values(port.links);
        res = [...res, ...links];
      }
    });
    return res;
  };
}
