/**
 * @flow
 *
 * @format
 */
import React from 'react';
import { connect } from 'react-redux';
import csvtojson from 'csvtojson';
import ReactJson from 'react-json-view';

import 'bootstrap/dist/css/bootstrap.min.css';
import { ConfigurationServiceHelper } from 'src/store/configuration';
import { PreferencesServiceHelper } from 'src/store/preferences';
import { Singleton as LocaleManager } from 'src/store/scenario/header/ScenarioLocaleManager';
import { withAuthorization, AuthenticatedCondition } from 'src/services/Session';
import { Claims } from 'src/constants/roles';

import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { i18n, changeLocale } from 'src/assets/locales';
import { availableLanguagesEditor } from 'src/assets/locales/languages';
import { UserView, LanguagesSettings } from './components';

export type SettingsScreenProps = {
  setEditionLocale: PreferencesServiceHelper.setEditionLocaleType,
  loadScreenplayEngine: ConfigurationServiceHelper.loadScreenplayEngineType,
  availableLocales: string[],
  editionLocale: string,
  t: (key: string) => string,
  validClaims: string[],
};

type State = {
  isScreenplayValid: boolean,
  screenPlayEngine: any,
  screenPlayEngineName?: string,
  loadedScreenplay?: any,
};

class SettingsScreen extends React.PureComponent<SettingsScreenProps, State> {
  static defaultProps = {};

  reader: ?FileReader = undefined;

  state = {
    isScreenplayValid: false,
    screenPlayEngine: undefined,
    screenPlayEngineName: undefined,
    loadedScreenplay: undefined,
  };

  updateValidity(screenPlayEngine, loading) {
    this.setState({
      isScreenplayValid: !loading && !!screenPlayEngine,
    });
  }

  loadCsvFile = (event) => {
    this.updateValidity(undefined, true);
    const { files } = event.target;
    const first = files[0];
    this.setState({ screenPlayEngineName: first.name });
    if (first) {
      this.reader = new FileReader();
      this.reader.onloadend = this.handleCsvLoaded;
      this.reader.readAsText(first);
    }
  };

  handleCsvLoaded = async () => {
    if (this.reader) {
      const csv = this.reader.result;
      const json = await csvtojson().fromString(csv);
      this.setState({
        screenPlayEngine: json,
      });
      this.updateValidity(json, false);
    }
  };

  startImport = async () => {
    const { screenPlayEngine } = this.state;
    const { loadScreenplayEngine } = this.props;
    if (loadScreenplayEngine) {
      const res = await loadScreenplayEngine(screenPlayEngine);
      this.setState({ loadedScreenplay: res });
    }
  };

  renderScreenPlayConfig = () => {
    const { isScreenplayValid, loadedScreenplay } = this.state;
    const { t } = this.props;
    return (
      <div className="card bg-light screenBlock mb-3">
        <div className="card-header">
          <h3 style={{ marginBottom: 0 }}>{t('screens.settings.screenPlayEngine.sectionTitle')}</h3>
        </div>
        <div className="card-body">
          <div className="custom-file mb-3">
            <input type="file" className="custom-file-input" accept=".csv" id="importCsv" onChange={this.loadCsvFile} />
            <label className="custom-file-label" htmlFor="importCsv" aria-describedby="inputGroupFileAddon02">
              {this.state.screenPlayEngineName || t('screens.settings.screenPlayEngine.importCsv')}
            </label>
            <small id="importCsvHelpBlock" className="form-text text-muted  mb-3">
              {t('screens.settings.screenPlayEngine.translationCsvHelp')}
            </small>
          </div>
          {loadedScreenplay && (
            <ReactJson
              src={loadedScreenplay}
              name={'screenplay'}
              collapsed={2}
              displayDataTypes={false}
              collapseStringsAfterLength={50}
            />
          )}
          <button
            className="btn btn-outline-secondary mb-3"
            type="button"
            id="button-addon2"
            onClick={this.startImport}
            disabled={!isScreenplayValid}
          >
            {t('general.import')}
          </button>
        </div>
      </div>
    );
  };

  setEditionLocale = (locale) => {
    const { setEditionLocale } = this.props;
    setEditionLocale(locale);
  };

  render() {
    const { availableLocales, editionLocale, setEditionLocale, t } = this.props;
    return (
      <div className="pageContainer">
        <div className="container-fluid component-controller">
          <div className="card bg-light screenBlock mb-3">
            <div className="card-header">
              <h3 style={{ marginBottom: 0 }}>{t('screens.settings.localisation.sectionTitle')}</h3>
            </div>
            <LanguagesSettings
              availableLanguages={availableLanguagesEditor}
              currentLanguage={i18n.language}
              changeLanguage={changeLocale}
              languageTypeTitle={t('screens.settings.localisation.uiLanguage')}
              explanationSettings={t('screens.settings.localisation.interfaceExplanation')}
              idDropdown={'interfaceLanguagesDropdown'}
            />
            <LanguagesSettings
              availableLanguages={availableLocales}
              currentLanguage={editionLocale}
              changeLanguage={setEditionLocale}
              languageTypeTitle={t('screens.settings.localisation.editableLanguage')}
              explanationSettings={t('screens.settings.localisation.editingExplanation')}
              idDropdown={'editingLanguagesDropdown'}
            />
          </div>
          <UserView />
          {!!this.props.validClaims.includes(Claims.Admin) && this.renderScreenPlayConfig()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  availableLocales: LocaleManager.availableLocales,
  editionLocale: state.preferences.editionLocale,
});

const mapDispatchToProps = {
  loadScreenplayEngine: ConfigurationServiceHelper.loadScreenplayEngine,
  setEditionLocale: PreferencesServiceHelper.setEditionLocale,
};

export default compose(
  withAuthorization(AuthenticatedCondition, Object.values(Claims)),
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('default'),
)(SettingsScreen);
