/**
 * @flow
 *
 * @format
 */
import { combineReducers } from 'redux';

import type { UserReducerState } from './user';
import { UserModule, UserReducerKey, UserReducerDefaultState } from './user';
import type { EventsReducerState } from './events';
import { EventsModule, EventsReducerKey, EventsReducerDefaultState } from './events';
import type { AMSReducerState } from './ams';
import { AMSModule, AMSReducerKey, AMSReducerDefaultState } from './ams';
import type { ScenarioReducerState } from './scenario';
import { ScenarioReducer, ScenarioReducerKey, ScenarioReducerDefaultState } from './scenario';
import type { ConfigurationReducerState } from './configuration';
import { ConfigurationModule, ConfigurationReducerKey, ConfigurationReducerDefaultState } from './configuration';
import type { PreferencesReducerState } from './preferences';
import { PreferencesModule, PreferencesReducerKey, PreferencesReducerDefaultState } from './preferences';
import type { RoomReducerState } from './room';
import { RoomReducer, RoomReducerKey, RoomReducerDefaultState } from './room';

export type AtlEditorState = {
  user: UserReducerState,
  events: EventsReducerState,
  ams: AMSReducerState,
  scenario: ScenarioReducerState,
  preferences: PreferencesReducerState,
  configuration: ConfigurationReducerState,
  room: RoomReducerState,
};

export const RootReducerDefaultState = {
  [UserReducerKey]: UserReducerDefaultState,
  [EventsReducerKey]: EventsReducerDefaultState,
  [AMSReducerKey]: AMSReducerDefaultState,
  [ConfigurationReducerKey]: ConfigurationReducerDefaultState,
  [PreferencesReducerKey]: PreferencesReducerDefaultState,
  [ScenarioReducerKey]: ScenarioReducerDefaultState,
  [RoomReducerKey]: RoomReducerDefaultState,
};

const RootReducer = combineReducers({
  [UserReducerKey]: UserModule.reducer,
  [EventsReducerKey]: EventsModule.reducer,
  [AMSReducerKey]: AMSModule.reducer,
  [ConfigurationReducerKey]: ConfigurationModule.reducer,
  [PreferencesReducerKey]: PreferencesModule.reducer,
  [ScenarioReducerKey]: ScenarioReducer,
  [RoomReducerKey]: RoomReducer,
});

export default RootReducer;
