/**
 * @flow
 *
 * @format
 */
import React from 'react';

import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { InputString, Loader } from 'src/pages/components';
import Firebase, { withFirebase } from 'src/services/Firebase';
import type { Team } from 'src/data/types/TeamType';
import type { User } from 'src/data/types/UserType';
import * as TeamServiceHelper from 'src/store/admin/TeamServiceHelper';
import EditTeamMembersView from 'src/pages/settings/components/EditTeamMembersView';
import EditTeamCreatorInfo from 'src/pages/settings/components/EditTeamCreatorInfo';
import EditScenariosTeam from './EditScenariosTeam';

type Props = {
  firebase: Firebase,
  updateTeam: TeamServiceHelper.updateTeamType,
  createTeam: TeamServiceHelper.createTeamType,
  item: Team,
  displayUpdatedTeam?: (
    uid: string,
    name: string,
    administrator: string,
    type: string,
    adminInfo: User,
    creatorName: string,
    webCreatorURL: string,
  ) => void,
  setTeamDisplayed?: (key?: Team) => void,
  setOtherTeamToUpdate: (key: Team) => void,
  t: (key: string) => string,
};

type State = {
  isLoading: boolean,
  isValid: boolean,
  administrator: string,
  name: string,
  uid: string,
  type: string,
  adminInfo?: User,
  creatorName: string,
  webCreatorURL: string,
};

class EditTeamView extends React.PureComponent<Props, State> {
  static defaultProps = {};

  state = {
    isLoading: false,
    name: '',
    isValid: false,
    uid: '',
    type: '',
    administrator: '',
    creatorName: '',
    webCreatorURL: '',
  };

  componentDidMount = () => {
    this.setItem(this.props.item);
  };

  didTeamChanged = (oldProps: Props) => {
    return (
      this.props.item?.uid !== oldProps.item?.uid ||
      this.props.item?.name !== oldProps.item?.name ||
      this.props.item?.administrator !== oldProps.item?.administrator ||
      this.props.item?.type !== oldProps.item?.type ||
      this.props.item?.adminInfo !== oldProps.item?.adminInfo ||
      this.props.item?.creatorName !== oldProps.item?.creatorName ||
      this.props.item?.webCreatorURL !== oldProps.item?.webCreatorURL
    );
  };

  componentDidUpdate = (oldProps: Props) => {
    if (this.didTeamChanged(oldProps)) {
      this.setItem(this.props.item);
    }
  };

  setItem = (item: Team = {}) => {
    this.setState(
      {
        name: item.name,
        administrator: item.administrator,
        uid: item.uid,
        type: item.type,
        adminInfo: item.adminInfo,
        creatorName: item.creatorName || '',
        webCreatorURL: item.webCreatorURL || '',
      },
      () => {
        this.updateValidity(this.state);
      },
    );
  };

  handleChange = (event) => {
    const { value, id: fieldName } = event.target;
    this.setState({ [fieldName]: value }, () => this.updateValidity(this.state));
  };

  /**
   * Checks if all the values are correct or not
   * @param {State} newVal
   */
  updateValidity = (newVal: State) => {
    const isValid: boolean =
      !!newVal.name &&
      !!newVal.name.length &&
      ['STANDARD', 'SMALL_GROUP', 'ATLANTIDE'].includes(newVal.type) &&
      !!newVal.administrator;
    this.setState({ isValid });
  };

  /**
   * Update the current team displayed, or creates a new one with the given info
   */
  updateTeam = async () => {
    const { uid, name, type, administrator, creatorName, webCreatorURL } = this.state;
    const { firebase, updateTeam, createTeam, displayUpdatedTeam } = this.props;
    this.setState({ isLoading: true });
    let updatedOrCreatedTeam: Team;
    if (uid && uid.length) {
      updatedOrCreatedTeam = await updateTeam(
        uid,
        name,
        administrator,
        type,
        firebase,
        undefined,
        undefined,
        creatorName,
        webCreatorURL,
      );
    } else {
      updatedOrCreatedTeam = await createTeam(name, administrator, type, creatorName, webCreatorURL, firebase);
    }
    if (updatedOrCreatedTeam && displayUpdatedTeam) {
      displayUpdatedTeam(
        updatedOrCreatedTeam.uid,
        updatedOrCreatedTeam.name,
        updatedOrCreatedTeam.administrator,
        updatedOrCreatedTeam.type,
        updatedOrCreatedTeam.adminInfo,
        updatedOrCreatedTeam.creatorName,
        updatedOrCreatedTeam.webCreatorURL,
      );
    }
    this.setState({ isLoading: false });
  };

  render() {
    const { name, type, uid, administrator, isValid, isLoading, adminInfo, creatorName, webCreatorURL } = this.state;
    const { t, setTeamDisplayed, item, setOtherTeamToUpdate } = this.props;
    return (
      <div className="card p-2 mb-2" style={{ display: 'block' }}>
        <div className="row flex-fill">
          <div className="col-8">
            <h4>{t('screens.admin.teams.teamInformation')}</h4>
          </div>
          {setTeamDisplayed && item && (
            <div className="col-4 d-flex justify-content-end">
              <button
                className="btn btn-outline-secondary"
                type="button"
                id="button-save"
                onClick={() => {
                  setTeamDisplayed();
                  this.setState({
                    name: '',
                    administrator: '',
                    uid: '',
                    type: '',
                    adminInfo: undefined,
                  });
                }}
              >
                {t('general.new')}
              </button>
            </div>
          )}
        </div>
        <InputString
          fieldName="uid"
          value={uid}
          label={t('screens.admin.teams.idLabel')}
          handleChange={this.handleChange}
          disabled={true}
        />
        <InputString
          fieldName="administrator"
          value={administrator}
          label={t('screens.admin.teams.administratorIdLabel')}
          handleChange={this.handleChange}
        />
        {adminInfo && (
          <>
            {adminInfo.collarNumber && (
              <div className="ml-3 mr-3 mt-3">
                <strong>{t('screens.admin.teams.collarNumberAdmin')} </strong>
                {adminInfo.collarNumber}
              </div>
            )}
            <div className="ml-3 mr-3 mt-3 mb-3">
              <strong>{t('screens.admin.teams.mailAdmin')} </strong>
              {adminInfo.email}
            </div>
          </>
        )}
        <InputString
          fieldName="type"
          value={type}
          label={t('screens.admin.teams.typeLabel')}
          handleChange={this.handleChange}
        />
        <InputString
          fieldName="name"
          value={name}
          label={t('screens.admin.teams.nameLabel')}
          handleChange={this.handleChange}
        />
        <EditTeamCreatorInfo
          creatorName={creatorName}
          webCreatorURL={webCreatorURL}
          setCreatorName={(creatorName) => this.setState({ creatorName })}
          setWebCreatorURL={(webCreatorURL) => this.setState({ webCreatorURL })}
          includesMembers={true}
        />
        <div style={{ minHeight: 30 }}>
          <button
            className="btn btn-outline-secondary"
            type="button"
            id="button-save"
            onClick={this.updateTeam}
            disabled={!isValid}
          >
            {item ? t('general.save') : t('general.create')}
          </button>
        </div>
        {this.props.item && (
          <>
            <EditTeamMembersView team={this.props.item} includesMembers={true} setTeamDisplayed={setTeamDisplayed} />
            <EditScenariosTeam
              team={this.props.item}
              setTeamDisplayed={setTeamDisplayed}
              setLoading={(loading) => this.setState({ isLoading: loading })}
              setOtherTeamToUpdate={setOtherTeamToUpdate}
            />
          </>
        )}
        {isLoading && <Loader />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: state.preferences.editionLocale,
});

const mapDispatchToProps = {
  updateTeam: TeamServiceHelper.updateTeam,
  createTeam: TeamServiceHelper.createTeam,
};

export default compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('default'),
)(EditTeamView);
