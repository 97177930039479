/**
 * @flow
 *
 * @format
 */
import * as React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Firebase, { withFirebase } from 'src/services/Firebase';
import * as UserServiceHelper from 'src/store/user/UserServiceHelper';

import type { User } from 'src/data/types/UserType';
import type { Team, UserIds } from 'src/data/types/TeamType';

import { InputSelect } from 'src/pages/components';
import ListTeamsUser from './ListTeamsUser';

type UserInfosTeamsType = {
  firebase: Firebase,
  getUserAllTeams: UserServiceHelper.getUserAllTeamsType,
  searchUser: UserServiceHelper.searchUserType,
  teamDisplayed?: Team,
  setTeamDisplayed: (key: string) => void,
  handleChange: (event: Event) => void,
  searchUserInput: string,
  searchUserSelect: { name: string, id: string },
  setSearchLoading: (loading: boolean) => void,
  otherTeamToUpdate: ?Team,
  searchUserTypes: Object,
  t: (key: string) => string,
};

const UserInfoTeams = ({
  firebase,
  getUserAllTeams,
  searchUser,
  teamDisplayed,
  setTeamDisplayed,
  handleChange,
  searchUserInput,
  searchUserSelect,
  searchUserTypes,
  setSearchLoading,
  otherTeamToUpdate,
  t,
}: UserInfosTeamsType) => {
  const [userDisplayed, setUserDisplayed] = React.useState<?User>();

  /**
   * Updates the list displayed, to adjust with the changes
   * @param {string} typeList
   * @returns the list updated
   */
  const updateTeamList = (
    typeList: 'userAdministratedTeams' | 'userEditingTeams' | 'userTestingTeams',
    userDisplayed: User,
  ) => {
    if (userDisplayed && teamDisplayed) {
      return userDisplayed[typeList]
        ? userDisplayed[typeList]
            .map((team) => {
              // We update the team displayed
              if (team.uid === teamDisplayed.uid) {
                // We check to see if it still belongs to this user
                let checkList;
                switch (typeList) {
                  // Still the admin ?
                  case 'userAdministratedTeams':
                    if (teamDisplayed.administrator === userDisplayed.uid) {
                      return teamDisplayed;
                    }
                    return false;
                  // Still an editor ?
                  case 'userEditingTeams':
                    // if he is admin, we still consider him as an editor
                    checkList =
                      teamDisplayed.studioEditorsData.filter(
                        (userIds) => userIds.uid.toLowerCase() === userDisplayed.uid.toLowerCase(),
                      )[0] || userDisplayed.userAdministratedTeams.map((team) => team.uid).includes(team.uid);
                    break;
                  // Still a tester ?
                  case 'userTestingTeams':
                    checkList = teamDisplayed.appTestersData.filter(
                      (userIds) => userIds.uid.toLowerCase() === userDisplayed.uid.toLowerCase(),
                    )[0];
                    break;
                  default:
                }
                if (checkList) {
                  return teamDisplayed;
                }
                return false;
              }
              return team;
            })
            // We need to drop the values that needed to be removed
            .filter((value) => value !== false)
        : [];
    }
    return [];
  };

  /**
   * Add the team concerning the user if needed
   * @param {string} typeList
   */
  const addNewEditorOrTesterTeam = (typeList: 'userEditingTeams' | 'userTestingTeams') => {
    const newTeamsEditorTester = [];
    let typeTeam: string;
    switch (typeList) {
      case 'userEditingTeams':
        typeTeam = 'studioEditorsData';
        break;
      default:
        typeTeam = 'appTestersData';
    }
    if (userDisplayed && teamDisplayed) {
      const teamEditorsTestersContainsUser: ?UserIds = teamDisplayed[typeTeam]
        ? teamDisplayed[typeTeam].filter((userIds) => userIds.uid.toLowerCase() === userDisplayed.uid.toLowerCase())[0]
        : undefined;
      const userEditorTesterListHaveEditorTester = userDisplayed[typeList].filter(
        (team) => team.uid === teamDisplayed.uid,
      )[0];
      if (teamEditorsTestersContainsUser && !userEditorTesterListHaveEditorTester) {
        newTeamsEditorTester.push(teamDisplayed);
      }
    }
    return newTeamsEditorTester;
  };

  /**
   * Checks if one of the user's team has been updated/created
   */
  React.useEffect(() => {
    if (userDisplayed && teamDisplayed) {
      const newTeamsArray: Array<Team> = [];
      const teamAdminExists: ?Team = userDisplayed.userAdministratedTeams.filter(
        (team) => team.uid === teamDisplayed.uid,
      )[0];
      if (teamDisplayed.administrator === userDisplayed.uid && !teamAdminExists) {
        newTeamsArray.push(teamDisplayed);
      }
      const newUserDisplayed = {
        ...userDisplayed,
        userAdministratedTeams: [...updateTeamList('userAdministratedTeams', userDisplayed), ...newTeamsArray],
      };
      setUserDisplayed({
        ...newUserDisplayed,
        userEditingTeams: [
          ...updateTeamList('userEditingTeams', newUserDisplayed),
          ...addNewEditorOrTesterTeam('userEditingTeams'),
        ],
        userTestingTeams: [
          ...updateTeamList('userTestingTeams', newUserDisplayed),
          ...addNewEditorOrTesterTeam('userTestingTeams'),
        ],
      });
    }
  }, [teamDisplayed]);

  /**
   * Scenario changed somewhere, we update the given team if it's in here
   */
  React.useEffect(() => {
    if (userDisplayed && otherTeamToUpdate) {
      setUserDisplayed({
        ...userDisplayed,
        userAdministratedTeams: userDisplayed.userAdministratedTeams.map((team) => {
          if (team.uid === otherTeamToUpdate.uid) {
            return otherTeamToUpdate;
          }
          return team;
        }),
        userEditingTeams: userDisplayed.userEditingTeams.map((team) => {
          if (team.uid === otherTeamToUpdate.uid) {
            return otherTeamToUpdate;
          }
          return team;
        }),
        userTestingTeams: userDisplayed.userTestingTeams.map((team) => {
          if (team.uid === otherTeamToUpdate.uid) {
            return otherTeamToUpdate;
          }
          return team;
        }),
      });
    }
  }, [otherTeamToUpdate]);

  /**
   * Executes fb function to retrieve the user with the corresponding criteria
   */
  const searchUserAction = async () => {
    setSearchLoading(true);
    if (searchUserInput && searchUserInput.length) {
      // We retrieve the user
      const userRetrieved = await searchUser(searchUserInput, searchUserSelect, firebase);
      if (userRetrieved) {
        // We  now want to retrieve all his teams
        const { administratedTeams, editingTeams, testingTeams } = await getUserAllTeams(userRetrieved.uid, firebase);
        userRetrieved.userAdministratedTeams = administratedTeams;
        userRetrieved.userTestingTeams = testingTeams;
        userRetrieved.userEditingTeams = editingTeams;
      }
      setUserDisplayed(userRetrieved);
    }
    setSearchLoading(false);
  };

  return (
    <div className="row flex-fill mb-3">
      <div className="col-8">
        <label className="text-uppercase font-weight-bold" htmlFor="searchUserInput">
          {t('screens.admin.teams.userSearchLabel')}
        </label>
        <div className="input-group">
          <input
            id="searchUserInput"
            type="text"
            value={searchUserInput}
            className="form-control"
            aria-describedby="button-addon2"
            onChange={handleChange}
          />
          <div className="input-group-append">
            <button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={searchUserAction}>
              {t('screens.admin.teams.buttonSearchTeam')}
            </button>
          </div>
        </div>
        <small className="form-text text-muted mb-4">{t('screens.admin.teams.caseSensitiveHelp')}</small>
      </div>
      <div className="col-4">
        <InputSelect
          fieldName="searchUserSelect"
          value={searchUserSelect}
          handleChange={handleChange}
          values={searchUserTypes}
          itemToId={(it) => it.id}
          itemToTitle={(it) => it.name}
          label={t('screens.admin.teams.searchTypeTeamLabel')}
          disableEmptyOption={true}
        />
      </div>
      {userDisplayed ? (
        <div className="flex-fill mt-2">
          <h4>{t('screens.admin.teams.userInfoLabel')}</h4>
          <div className="mt-3 mr-3 ml-3">
            <strong>UID : </strong>
            {userDisplayed.uid}
          </div>
          {userDisplayed.collarNumber && (
            <div className=" ml-3 mr-3">
              <strong>{t('screens.admin.teams.collarNumberAdmin')} </strong>
              {userDisplayed.collarNumber}
            </div>
          )}
          {userDisplayed.email && (
            <div className="mr-3 ml-3 mb-4">
              <strong>{t('screens.admin.teams.mailAdmin')} </strong>
              {userDisplayed.email}
            </div>
          )}
          {userDisplayed && userDisplayed.userAdministratedTeams.length > 0 && (
            <ListTeamsUser
              userDisplayed={userDisplayed}
              setTeamDisplayed={setTeamDisplayed}
              titleLabel={t('screens.admin.teams.administratedTeamsLabel')}
              teamsType={'userAdministratedTeams'}
            />
          )}
          {userDisplayed && userDisplayed.userTestingTeams.length > 0 && (
            <ListTeamsUser
              userDisplayed={userDisplayed}
              setTeamDisplayed={setTeamDisplayed}
              titleLabel={t('screens.admin.teams.editingTeamsLabel')}
              teamsType={'userTestingTeams'}
            />
          )}
          {userDisplayed && userDisplayed.userEditingTeams.length > 0 && (
            <ListTeamsUser
              userDisplayed={userDisplayed}
              setTeamDisplayed={setTeamDisplayed}
              titleLabel={t('screens.admin.teams.testingTeamsLabel')}
              teamsType={'userEditingTeams'}
            />
          )}
        </div>
      ) : (
        <p>{t('screens.admin.teams.nothingToDisplay')}</p>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  locale: state.preferences.editionLocale,
});

const mapDispatchToProps = {
  getUserAllTeams: UserServiceHelper.getUserAllTeams,
  searchUser: UserServiceHelper.searchUser,
};

export default compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('default'),
)(UserInfoTeams);
