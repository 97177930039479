/**
 * @flow
 *
 * @format
 */
import * as React from 'react';
import { connect } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import LocalizedFile from 'src/data/LocalizedFile';
import { EventsServiceHelper, NotificationTypes } from 'src/store/events';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HelpButton from '../HelpButton';

export type handleFileSelectedType = (name: string, locale: string, file: File) => any;

export interface InputLocalizedFileProps {
  fieldName: string;
  value?: ?LocalizedFile;
  label: string;
  help?: ?string;
  disabled?: boolean;
  hidden?: boolean;
  multiline?: boolean;
  helpInfos: any;
  t: (key: string) => string;
  accept: string;
  handleFileSelected: handleFileSelectedType;
  removeFileLocale: (fieldName: string, locale: string, index: ?number) => any;
  contentLocale: string;
  handleContentChange: (fieldName: string, value: string) => any;
  separatorBefore?: boolean;
  sizeWarnLimit: number;
  sizeErrorLimit: number;
  onlyHandleDefault: boolean;
  addNotif: EventsServiceHelper.addNotifType;
  onFocus?: () => any;
  inputStyle?: ?any;
}

class InputLocalizedFile extends React.Component<InputLocalizedFileProps> {
  static defaultProps = {
    disabled: false,
    hidden: false,
    multiline: false,
    helpInfos: undefined,
    separatorBefore: false,
    accept: '.png,.jpg,.jpeg',
    onlyHandleDefault: false,
    inputStyle: {},
  };

  dispatchFileSizeError = (size: number) => {
    const { addNotif, sizeErrorLimit } = this.props;
    addNotif(
      NotificationTypes.ERROR,
      'E_FILE_TO_BIG',
      `Size:${size / 1000000}MB expected max ${sizeErrorLimit / 1000000}MB`,
      0,
    );
  };

  dispatchFileSizeWarn = (size: number) => {
    const { addNotif, sizeWarnLimit } = this.props;
    addNotif(
      NotificationTypes.WARN,
      'W_FILE_BIG',
      `Size:${size / 1000000}MB expected max ${sizeWarnLimit / 1000000}MB`,
      0,
    );
  };

  handleFileChange = (event: any) => {
    const { handleFileSelected, fieldName, sizeWarnLimit, sizeErrorLimit } = this.props;
    const { files, id } = event.target;
    const first = files[0];
    const size = first && first.size;
    if (size > sizeErrorLimit) {
      this.dispatchFileSizeError(size);
    } else {
      if (size > sizeWarnLimit) {
        this.dispatchFileSizeWarn(size);
      }
      if (handleFileSelected) {
        if (first) {
          handleFileSelected(fieldName, id, first);
        }
      }
    }
  };

  renderFileLine = (key: string, value: { name: string }) => {
    const { disabled, fieldName, removeFileLocale, accept, separatorBefore } = this.props;
    return (
      <div className="input-group" key={key}>
        {separatorBefore && <hr />}
        <div className="input-group-prepend">
          <div className="input-group-text">{key}</div>
        </div>
        <div className="custom-file">
          <input
            type="file"
            accept={accept}
            className="form-control custom-file-input"
            id={key}
            onChange={this.handleFileChange}
            disabled={disabled}
            aria-describedby={`${fieldName}Help`}
            placeholder={'...'}
            multiple={false}
            style={this.props.inputStyle}
          />
          <label className="custom-file-label" style={{ overflow: 'hidden', wordWrap: '...' }} htmlFor={fieldName}>
            {value && value.name && value.name}
          </label>
        </div>
        <div className="input-group-append">
          <button
            className="btn btn-outline-secondary delete-inline"
            type="button"
            id="button-addon2"
            value={key}
            onClick={() => removeFileLocale(fieldName, key)}
          >
            <FontAwesomeIcon icon={['fad', 'trash-alt']} />
          </button>
        </div>
      </div>
    );
  };

  render() {
    const {
      fieldName,
      value,
      label,
      hidden,
      helpInfos,
      contentLocale,
      separatorBefore,
      onlyHandleDefault,
    } = this.props;
    return (
      <div className="form-group" key={fieldName} hidden={hidden}>
        {separatorBefore && <hr />}
        <label htmlFor={fieldName} className="text-capitalize">
          {`${label} `}
          {helpInfos &&
            helpInfos.map((helpInfo, index) => (
              <HelpButton
                key={fieldName + index}
                helpStrings={helpInfo.content}
                id={fieldName}
                title={helpInfo.title}
                label={helpInfo.btnLabel}
                display={'inline'}
                className={'ml-auto btn btn-outline-warning ml-2'}
              />
            ))}
        </label>
        {/*
        <InputLocalizedString
          fieldName={fieldName}
          value={value && value.content}
          localized={true}
          label={t('general.alt')}
          labelSmall={true}
          help={t('general.altHelp')}
          disabled={disabled}
          multiline={multiline}
          onFocus={onFocus}
          handleLocalizedContentChange={e => handleContentChange(fieldName, e.target.value)}
          hidden={hidden}
          helpInfos={helpInfos}
          contentLocale={contentLocale}
        />
        */}
        {value && this.renderFileLine('default', value.files.default)}
        {value && !onlyHandleDefault && this.renderFileLine(contentLocale, value.files[contentLocale])}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  sizeWarnLimit: ownProps.sizeWarnLimit || state.configuration.fileSizeLimits.image / 10,
  sizeErrorLimit: ownProps.sizeErrorLimit || state.configuration.fileSizeLimits.image,
});

const mapDispatchToProps = {
  addNotif: EventsServiceHelper.addNotif,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation('default'))(InputLocalizedFile);
