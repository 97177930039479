/**
 * /* eslint-disable no-alert
 *
 * @format
 */

/**
 * @flow
 *
 * @format
 */
import React from 'react';

import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { Loader, InputString } from 'src/pages/components';
import { EventsServiceHelper, NotificationTypes } from 'src/store/events';
import { FirebaseSingleton as firebase, FirebaseHelper } from 'src/services/Firebase';
import VisibilitySensor from 'react-visibility-sensor';

import { TabContent } from '../components';

type Props = {
  migrateAllUsersAsync: FirebaseHelper.migrateAllUsersAsyncType,
  currentEditorVersion: string,
  addNotif: EventsServiceHelper.addNotifType,
  t: (key: string) => string,
};

type State = {
  isLoading: boolean,
  latestAndroid: string,
  latestIos: string,
  advisedAndroid: string,
  advisedIos: string,
  requiredIos: string,
  requiredAndroid: string,
  isValid: string,
  validitys: {
    latestAndroid: boolean,
    latestIos: boolean,
    advisedAndroid: boolean,
    advisedIos: boolean,
    requiredIos: boolean,
    requiredAndroid: boolean,
  },
};

class ApplicationsTab extends React.PureComponent<Props, State> {
  static defaultProps = {};

  state = {
    isLoading: false,
    latestAndroid: '',
    latestIos: '',
    advisedAndroid: '',
    advisedIos: '',
    requiredIos: '',
    requiredAndroid: '',
    isValid: false,
    validitys: {
      latestAndroid: false,
      latestIos: false,
      advisedAndroid: false,
      advisedIos: false,
      requiredIos: false,
      requiredAndroid: false,
    },
  };

  onVisibilityChanged = (visible: boolean) => {
    if (visible) {
      this.getServerState();
    }
  };

  checkValidity = (value: string) => value.match(/^(\d\.?)*$/);

  handleChange = (event) => {
    const { value } = event.target;
    const fieldName = event.target.id;
    const { validitys } = this.state;
    validitys[fieldName] = this.checkValidity(value);
    const isValid = Object.values(validitys).reduce((cur, acc) => cur && acc);
    const newState: $Shape<State> = {
      [fieldName]: value,
      validitys,
      isValid,
    };
    this.setState(newState);
  };

  getServerState = async () => {
    const snapshot = await firebase.applicationVersions().once('value');
    if (snapshot.exists()) {
      const val = snapshot.val();
      this.setState({
        latestAndroid: val.latest.android,
        latestIos: val.latest.ios,
        advisedAndroid: val.advised.android,
        advisedIos: val.advised.ios,
        requiredAndroid: val.required.android,
        requiredIos: val.required.ios,
        validitys: {
          latestAndroid: true,
          latestIos: true,
          advisedAndroid: true,
          advisedIos: true,
          requiredIos: true,
          requiredAndroid: true,
        },
        isValid: true,
      });
    }
  };

  _updateAppVersions = () => {
    const { latestAndroid, latestIos, advisedAndroid, advisedIos, requiredAndroid, requiredIos, isValid } = this.state;
    if (isValid) {
      firebase.applicationVersions().set({
        latest: {
          ios: latestIos,
          android: latestAndroid,
        },
        required: {
          ios: requiredIos,
          android: requiredAndroid,
        },
        advised: {
          ios: advisedIos,
          android: advisedAndroid,
        },
      });
      this.props.addNotif(NotificationTypes.SUCCESS, 'S_APP_VERSION_UPDATE');
    }
  };

  // eslint-disable-next-line class-methods-use-this
  render() {
    const {
      isLoading,
      latestAndroid,
      latestIos,
      advisedAndroid,
      advisedIos,
      requiredAndroid,
      requiredIos,
      isValid,
    } = this.state;
    const { t } = this.props;
    return (
      <TabContent name="applications">
        <VisibilitySensor onChange={this.onVisibilityChanged} partialVisibility>
          <React.Fragment>
            <div className="card-header">
              <h3>{t('screens.admin.applications.sectionTitle')}</h3>
            </div>

            <div className="card-body p-2 pl-4">
              <div className="list-group pb-10">
                <h4>{t('screens.admin.applications.latest.title')}</h4>
                {t('screens.admin.applications.latest.message')}
                <div className="d-flex bd-highlight">
                  <InputString
                    style={{ marginRight: 5 }}
                    fieldName="latestAndroid"
                    value={latestAndroid}
                    label={t('screens.admin.applications.android')}
                    handleChange={this.handleChange}
                  />
                  <InputString
                    fieldName="latestIos"
                    value={latestIos}
                    label={t('screens.admin.applications.ios')}
                    handleChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="list-group pb-10">
                <h4>{t('screens.admin.applications.advised.title')}</h4>
                {t('screens.admin.applications.advised.message')}
                <div className="d-flex bd-highlight">
                  <InputString
                    style={{ marginRight: 5 }}
                    fieldName="advisedAndroid"
                    value={advisedAndroid}
                    label={t('screens.admin.applications.android')}
                    handleChange={this.handleChange}
                  />
                  <InputString
                    fieldName="advisedIos"
                    value={advisedIos}
                    label={t('screens.admin.applications.ios')}
                    handleChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="list-group pb-10">
                <h4>{t('screens.admin.applications.required.title')}</h4>
                {t('screens.admin.applications.required.message')}
                <div className="d-flex bd-highlight">
                  <InputString
                    style={{ marginRight: 5 }}
                    fieldName="requiredAndroid"
                    value={requiredAndroid}
                    label={t('screens.admin.applications.android')}
                    handleChange={this.handleChange}
                  />
                  <InputString
                    fieldName="requiredIos"
                    value={requiredIos}
                    label={t('screens.admin.applications.ios')}
                    handleChange={this.handleChange}
                  />
                </div>
              </div>
              <button
                className="btn btn-outline-secondary"
                style={{ marginLeft: 10 }}
                type="button"
                id="button-save"
                onClick={this._updateAppVersions}
                disabled={!isValid}
              >
                {t('screens.admin.applications.updateAppVersions')}
              </button>
            </div>

            {isLoading && <Loader />}
          </React.Fragment>
        </VisibilitySensor>
      </TabContent>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: state.preferences.editionLocale,
});

const mapDispatchToProps = {
  addNotif: EventsServiceHelper.addNotif,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation('default'))(ApplicationsTab);
