/**
 * @flow
 *
 * @format
 */
import * as React from 'react';
import { connect } from 'react-redux';

import { User } from '@firebase/auth';
import { object } from 'prop-types'; // TODO

import packageJson from 'src/../package.json';
import * as ROUTES from 'src/constants/routes';
import { ScenarioServiceHelper } from 'src/store/scenario';
import { AmsServiceHelper } from 'src/store/ams';
import { PreferencesServiceHelper } from 'src/store/preferences';
import { compareVersions } from 'src/utils';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';

type Props = {
  authUser: User,
  maintaining: boolean,
  scenarioId?: string,
  ams?: string,
  cleanupScenarios: ScenarioServiceHelper.cleanupType,
  cleanupAms: AmsServiceHelper.cleanupType,
  cleanupUrlProperties: PreferencesServiceHelper.cleanupUrlPropertiesType,
  firebase: any,
};

type State = {
  authUser: User,
  isMaintaining: boolean,
  maintenanceData: { maintainers?: string[], startTime?: number },
  requiredEditorVersion?: string,
  updateRequired: boolean,
  nextDeploymentTime: number,
};

const CURRENT_EDITOR_VERSION = packageJson.version;

const mapStateToProps = (state) => ({
  authUser: state.user ? state.user.user : null,
  scenarioId: state.scenario.header && state.scenario.header.id,
  ams: state.preferences.urlProperties.ams,
  cities: state.preferences.urlProperties.cities,
});

const mapDispatchToProps = {
  cleanupScenarios: ScenarioServiceHelper.cleanup,
  cleanupAms: AmsServiceHelper.cleanup,
  cleanupUrlProperties: PreferencesServiceHelper.cleanupUrlProperties,
};

const withAuthentication = (Component: React.AbstractComponent<Props>) => {
  class WithAuthentication extends React.Component<Props, State> {
    static contextTypes = {
      router: object,
    };

    unsubscribeMaintenance: () => void;

    unsubscribeVersion: () => void;

    state = {
      isMaintaining: false,
      authUser: undefined,
      maintenanceData: {},
      requiredEditorVersion: undefined,
      updateRequired: false,
      nextDeploymentTime: 0,
    };

    componentDidUpdate(oldProps) {
      if (this.props.authUser !== oldProps.authUser) {
        this.setState({ authUser: this.props.authUser });
      }
    }

    componentDidMount() {
      this.unsubscribeMaintenance = this.props.firebase.editorMaintenance().on('value', (snapshot) => {
        if (snapshot && snapshot.exists()) {
          const maintenanceData = snapshot.val();
          this.setState({ isMaintaining: true, maintenanceData });
          this.closeContent();
        } else {
          this.setState({ isMaintaining: false, maintenanceData: {} });
        }
      });
      this.unsubscribeVersion = this.props.firebase.editorDeployedVersion().on('value', (snapshot) => {
        if (snapshot && snapshot.exists()) {
          const data = snapshot.val();
          this.setState({ requiredEditorVersion: data.required });
          const compareRes = compareVersions(data.required, CURRENT_EDITOR_VERSION);
          if (compareRes > 0) {
            this.setState({ updateRequired: true });
          }
          if (data.nextDeploymentTime) {
            const nextCompareRes = compareVersions(data.nextDeploymentVersion, CURRENT_EDITOR_VERSION);
            if (nextCompareRes > 0) {
              const now = Date.now();
              if (now > data.nextDeploymentTime) {
                this.setState({ updateRequired: true, requiredEditorVersion: data.nextDeploymentVersion });
              } else {
                this.setState({ nextDeploymentTime: data.nextDeploymentTime });
              }
            }
          }
        }
      });
    }

    componentWillUnmount() {
      this.unsubscribeMaintenance();
      this.unsubscribeVersion();
    }

    closeContent = async () => {
      const { scenarioId, ams, cleanupScenarios, cleanupAms, cleanupUrlProperties } = this.props;
      let shouldRedirect = false;
      if (ams) {
        await cleanupAms();
        shouldRedirect = true;
      }
      if (scenarioId) {
        await cleanupScenarios();
        shouldRedirect = true;
      }
      if (shouldRedirect) {
        await cleanupUrlProperties();
        this.context.router.history.push(ROUTES.SCENARIO_DASHBOARD);
      }
    };

    render() {
      return (
        <AuthUserContext.Provider value={this.props.authUser}>
          <Component
            {...this.props}
            isMaintaining={this.state.isMaintaining}
            maintenanceData={this.state.maintenanceData}
            requiredEditorVersion={this.state.requiredEditorVersion}
            email={this.props.authUser && this.props.authUser.email}
            updateRequired={this.state.updateRequired}
            nextDeploymentTime={this.state.nextDeploymentTime}
          />
        </AuthUserContext.Provider>
      );
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(withFirebase(WithAuthentication));
};

export default withAuthentication;
