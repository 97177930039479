/**
 * @flow
 *
 * @format
 */

import type { User } from './UserType';

export type UserIds = {
  uid: string,
  email: string,
  collarNumber?: string,
};

export type Team = {
  administrator: string,
  name: string,
  scenarioIds: Array<string>,
  type: string,
  uid: string,
  appTesters: Array<UserIds>,
  studioEditorsData: Array<UserIds>,
  appTestersData: Array<UserIds>,
  adminInfo: User,
  creatorName: string,
  webCreatorURL: string,
};

export const TeamTypes = {
  STANDARD: 'STANDARD',
  SMALL_GROUP: 'SMALL_GROUP',
  ATLANTIDE: 'ATLANTIDE',
};

export const TeamConfigs = {
  [TeamTypes.STANDARD]: {
    authorizedAppTesterCount: 5,
    authorizedStudioMemberCount: 1,
    authorizedScenarioCount: 3,
  },
  [TeamTypes.SMALL_GROUP]: {
    authorizedAppTesterCount: 3,
    authorizedStudioMemberCount: 3,
    authorizedScenarioCount: 3,
  },
  [TeamTypes.ATLANTIDE]: {
    authorizedAppTesterCount: Number.MAX_SAFE_INTEGER,
    authorizedStudioMemberCount: Number.MAX_SAFE_INTEGER,
    authorizedScenarioCount: Number.MAX_SAFE_INTEGER,
  },
};

/**
 * Returns 0 or the number if it exists
 */
export const numberToDisplay = (team: Team, type: 'scenarioIds' | 'appTestersData' | 'studioEditorsData') =>
  team[type] ? team[type].length : 0;

/**
 * Returns the limit or the value if it exceeds it
 */
export const limitOrMax = (team: Team, type: 'scenarioIds' | 'appTestersData' | 'studioEditorsData') => {
  if (team.type === TeamTypes.ATLANTIDE) {
    return '';
  }
  let limit;
  switch (type) {
    case 'scenarioIds':
      limit = 'authorizedScenarioCount';
      break;
    case 'appTestersData':
      limit = 'authorizedAppTesterCount';
      break;
    case 'studioEditorsData':
    default:
      limit = 'authorizedStudioMemberCount';
  }
  return ` / ${Math.max(TeamConfigs[team.type][limit], numberToDisplay(team, type))}`;
};
