/**
 * @flow
 *
 * @format
 */
import React from 'react';
import { connect } from 'react-redux';
import * as ROUTES from 'src/constants/routes';

import 'bootstrap/dist/css/bootstrap.min.css';
import { ScenarioServiceHelper } from 'src/store/scenario';
import { withAuthorization, AuthenticatedCondition } from 'src/services/Session';
import { Claims } from 'src/constants/roles';
import { withUrlProperties } from 'src/pages/components/WithUrlProperties';

import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Firebase, { withFirebase } from 'src/services/Firebase';
import type { ScenarioReducerState } from 'src/store/scenario';
import { Loader, withConfirm } from 'src/pages/components';
import type { withConfirmProps } from 'src/pages/components';
import VisibilitySensor from 'react-visibility-sensor';
import { UserServiceHelper } from 'src/store/user';
import InputBoolean from '../components/inputs/InputBoolean';

export type ExportScenarioScreenProps = withConfirmProps & {
  exportAll: ScenarioServiceHelper.exportScenarioType,
  updateOnFirebase: ScenarioServiceHelper.saveScenarioInFirebaseType,
  getTeams: UserServiceHelper.getUserAdministratedTeamsType,
  firebase: Firebase,
  reduxState: ScenarioReducerState,
  generateRelease: ScenarioServiceHelper.generateReleaseType,
  engineVersion: number,
  scenarioId: string,
  scenarioVersion: string,
  isConfirmedEditor: boolean,
  scenarioTeam?: string,
  t: (key: string) => string,
};

type State = {
  isLoading: boolean,
  changes: any[],
  editors: string[],
  itemIds: string[],
  sections: string[],
  removeAllPreviousReleases: boolean,
  hasTesters: boolean,
};

class ExportScenarioScreen extends React.PureComponent<ExportScenarioScreenProps, State> {
  static defaultProps = {};

  reader = undefined;

  state = {
    isLoading: false,
    changes: [],
    editors: [],
    itemIds: [],
    sections: [],
    removeAllPreviousReleases: false,
    hasTesters: true,
  };

  componentDidMount = () => {
    this.refreshChanges();
  };

  refreshTeamsAsync = async (visible: boolean) => {
    if (visible) {
      const newTeams = await this.props.getTeams(undefined, this.props.firebase);
      const { scenarioTeam } = this.props;
      if (scenarioTeam && newTeams) {
        const teamData = newTeams.find((it) => it.uid === scenarioTeam);
        this.setState({ hasTesters: teamData && teamData.appTestersData && !!teamData.appTestersData.length });
      }
    }
  };

  updateOnFirebase = () => {
    const { reduxState, updateOnFirebase, firebase } = this.props;
    if (updateOnFirebase && reduxState) {
      updateOnFirebase(reduxState.header.id, reduxState, firebase);
    }
  };

  refreshChanges = async () => {
    const { scenarioId, scenarioVersion, firebase } = this.props;
    try {
      const { changes, editors, itemIds, sections } = await ScenarioServiceHelper.listChangesAsync(
        scenarioId,
        [scenarioVersion],
        firebase,
      );
      this.setState({
        changes,
        editors,
        itemIds,
        sections,
      });
    } catch (error) {
      console.error('Could not load changelist', error);
    }
  };

  generateDryRelease = () => {
    this._generateRelease(true);
  };

  generateRelease = () => {
    this._generateRelease(false);
  };

  _generateRelease = (dryRun: boolean) => {
    const { reduxState, generateRelease, firebase, engineVersion } = this.props;
    const { removeAllPreviousReleases } = this.state;
    const generate = () => {
      this.setState({ isLoading: true });
      generateRelease(
        reduxState.header.id,
        reduxState,
        engineVersion,
        removeAllPreviousReleases,
        this.props.t,
        firebase,
        dryRun,
      )
        .then(() => {
          this.setState({ isLoading: false });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ isLoading: false });
        });
    };
    if (generateRelease && reduxState) {
      if (removeAllPreviousReleases) {
        const { t, alert } = this.props;
        alert(t('screens.export.beta.removeAllPreviousReleasesHelp'), [
          { text: t('general.confirm'), onPress: generate },
          { text: t('general.cancel'), onPress: () => {} },
        ]);
      } else {
        generate();
      }
    }
  };

  exportAll = () => {
    const { reduxState, exportAll } = this.props;
    if (exportAll && reduxState) {
      exportAll(reduxState);
    }
  };

  handleChange = (event) => {
    const { value } = event.target;
    const fieldName = event.target.id;
    this.setState({ [fieldName]: value });
    const newVal = { ...this.state };
    newVal[fieldName] = value;
  };

  modaleCallbacks = undefined;

  modalMounted = (callbacks) => {
    this.modaleCallbacks = callbacks;
  };

  alert(message, buttons) {
    if (this.modaleCallbacks) {
      this.modaleCallbacks.alert(message, buttons);
    }
  }

  createScenario = () => {
    this.props.history.push(ROUTES.SETTINGS);
  };

  render() {
    const { isLoading, removeAllPreviousReleases, hasTesters } = this.state;
    const { t, isConfirmedEditor } = this.props;
    return (
      <div className="pageContainer">
        <VisibilitySensor onChange={this.refreshTeamsAsync} partialVisibility>
          <div>
            <div className="container-fluid component-controller">
              <div className="card bg-light screenBlock mb-3">
                <div className="card-header">
                  <h3>{t('screens.export.beta.sectionTitle')}</h3>
                </div>
                <div className="card-body">
                  <p className="form-text text-muted  mb-3">{t('screens.export.beta.desc')}</p>
                  <div>
                    <h5>{t('screens.export.beta.changeList')}</h5>
                    <p>
                      {t('screens.export.beta.editors')} {this.state.editors.join(', ')}
                    </p>
                    <p>
                      {t('screens.export.beta.sections')} {this.state.sections.join(', ')}
                    </p>
                    <p>
                      {t('screens.export.beta.items')} {this.state.itemIds.join(', ')}
                    </p>
                  </div>

                  {!hasTesters && (
                    <div className="empty-container">
                      <hr />
                      <div
                        className=" btn btn-light empty-button"
                        style={{ float: 'right' }}
                        onClick={this.createScenario}
                      >
                        {t('screens.export.addTesters')}
                      </div>
                      <p className="">⚠ {t('screens.export.noTestersTitle')}</p>
                      <br />
                      <hr />
                    </div>
                  )}
                  {isConfirmedEditor && (
                    <InputBoolean
                      fieldName="removeAllPreviousReleases"
                      value={removeAllPreviousReleases}
                      label={t('screens.export.beta.removeAllPreviousReleasesLabel')}
                      help={t('screens.export.beta.removeAllPreviousReleasesHelp')}
                      handleChange={this.handleChange}
                    />
                  )}

                  <button className="btn btn-primary mb-3" type="button" onClick={this.generateRelease}>
                    {t('general.export')}
                  </button>

                  {isConfirmedEditor && (
                    <button
                      className="btn btn-outline-secondary mb-3 ml-2"
                      type="button"
                      onClick={this.generateDryRelease}
                    >
                      {t('general.dryRun')}
                    </button>
                  )}
                </div>
              </div>
            </div>
            {isLoading && <Loader />}
          </div>
        </VisibilitySensor>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  scenarioId: state.scenario.header.id,
  scenarioTeam: state.scenario.header.teamId,
  scenarioVersion: state.scenario.header.lastVersion,
  reduxState: state.scenario,
  jsonRoot: 'Scénario',
  engineVersion: state.configuration.engineVersion,
  isConfirmedEditor: ownProps.validClaims.includes(Claims.Admin) || ownProps.validClaims.includes(Claims.Moderator),
});

const mapDispatchToProps = {
  exportAll: ScenarioServiceHelper.exportToZip,
  generateRelease: ScenarioServiceHelper.generateRelease,
  getTeams: UserServiceHelper.getUserAdministratedTeams,
};

export default compose(
  withUrlProperties,
  withConfirm,
  withFirebase,
  withAuthorization(AuthenticatedCondition, [Claims.Editor, Claims.Moderator, Claims.Admin]),
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('default'),
)(ExportScenarioScreen);
